import React, { useState, useEffect } from "react"
import { Link as RouterLink } from "react-router-dom"
import { useParams, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import {
  listCamboDetails,
  updateCamboScoring,
} from "../../actions/camboActions"
import { CAMBO_SCORING_UPDATE_RESET } from "../../constants/camboConstants"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import ReactCountryFlag from "react-country-flag"
import MobileBasicLeaberboard from "../../components/cambocup/leaderboards/MobileBasicLeaberboard"
import {
  OutlinedInput,
  InputLabel,
  Box,
  Typography,
  Button,
  LinearProgress,
  Alert,
  Grid,
  AlertTitle,
  FormControl,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material"

const Leaderboard = () => {
  // react router dom
  const params = useParams()
  const navigate = useNavigate()

  // redux
  const dispatch = useDispatch()

  // component state
  const [dayOne, setDayOne] = useState(0)
  const [dayTwo, setDayTwo] = useState(0)
  const [dayThree, setDayThree] = useState(0)
  const [dayFour, setDayFour] = useState(0)
  const [_id, setId] = useState("")
  const [open, setOpen] = React.useState({})

  // handle open score input for individuals
  const handleOpen = (playerId) =>
    setOpen((prev) => ({ ...prev, [playerId]: true }))
  const handleClose = (playerId) =>
    setOpen((prev) => ({ ...prev, [playerId]: false }))

  //user details state from store.js
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin // deconstruct reducer

  //cambo details state from store.js
  const camboDetails = useSelector((state) => state.camboDetails)
  const { cambo, loading, error } = camboDetails

  //update cambo scoring state from store.js
  const updateScoring = useSelector((state) => state.updateScoring)
  const { success, error: errorScoring } = updateScoring

  // dispatch
  useEffect(() => {
    if (success) {
      dispatch({ type: CAMBO_SCORING_UPDATE_RESET })
    }
    if (userInfo) {
      dispatch(listCamboDetails(params.id))
    } else {
      navigate("/login")
    }
  }, [params, dispatch, navigate, userInfo, success])

  // submit scores handler
  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      updateCamboScoring(params.id, {
        _id,
        dayOne,
        dayTwo,
        dayThree,
        dayFour,
      })
    )
    // reload window after submission to stop a double submit
    window.location.reload()
  }

  return (
    <>
      {/* checks if loading/error & displays outcome */}
      {loading ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress color='success' />
        </Box>
      ) : error ? (
        <Box sx={{ width: "100%" }}>
          <Alert severity='error'>
            <AlertTitle>Error</AlertTitle>
            {error}
          </Alert>
        </Box>
      ) : (
        <>
          {/* go back button */}
          <Button
            size='small'
            variant='contained'
            component={RouterLink}
            to={`/cambo/${cambo._id}`}
          >
            Back
          </Button>

          {/* header label */}
          <Typography variant='h4'>{cambo.country} Leaderboard</Typography>

          {/* leaderboard table */}
          <TableContainer
            component={Paper}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <Table sx={{ minWidth: 650 }} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>
                    <Typography variant='h6'>Total</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>Rd 1</Typography>
                  </TableCell>
                  <TableCell>HDC</TableCell>
                  <TableCell>
                    <Typography variant='h6'>Rd 2</Typography>
                  </TableCell>
                  <TableCell>HDC</TableCell>
                  <TableCell>
                    <Typography variant='h6'>Rd 3</Typography>
                  </TableCell>
                  <TableCell>HDC</TableCell>
                  <TableCell>
                    <Typography variant='h6'>Rd 4</Typography>
                  </TableCell>
                  <TableCell>HDC</TableCell>

                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* large Screen View  */}
                {/* sorts player leader from highest total */}
                {/* chc stands for course handicap - different each course */}
                {cambo.players
                  .sort((a, b) => b.total - a.total)
                  .map((player, i) => (
                    <React.Fragment key={player._id}>
                      <TableRow>
                        <TableCell>
                          <ReactCountryFlag
                            countryCode={player.country}
                            svg
                            style={{
                              fontSize: "2em",
                              lineHeight: "2em",
                            }}
                          />
                        </TableCell>
                        <TableCell>{player.firstName} </TableCell>
                        <TableCell>{player.lastName}</TableCell>
                        <TableCell
                          align='center'
                          sx={{
                            padding: "0px 0px",
                            backgroundColor: "lightgray",
                            color: "red",
                          }}
                        >
                          {player.total}
                        </TableCell>
                        <TableCell align='center'>{player.dayOne}</TableCell>
                        <TableCell
                          align='center'
                          sx={{
                            padding: "0px 0px",
                            color: "blue",
                          }}
                        >
                          {player.chc1}
                        </TableCell>
                        <TableCell
                          align='center'
                          sx={{
                            padding: "0px 0px",
                            backgroundColor: "lightgray",
                          }}
                        >
                          {player.dayTwo}
                        </TableCell>
                        <TableCell
                          align='center'
                          sx={{
                            padding: "0px 0px",
                            color: "blue",
                            backgroundColor: "lightgray",
                          }}
                        >
                          {player.chc2}
                        </TableCell>
                        <TableCell align='center'>{player.dayThree}</TableCell>
                        <TableCell
                          align='center'
                          sx={{
                            padding: "0px 0px",
                            color: "blue",
                          }}
                        >
                          {player.chc3}
                        </TableCell>
                        <TableCell
                          align='center'
                          sx={{
                            padding: "0px 0px",
                            backgroundColor: "lightgray",
                          }}
                        >
                          {player.dayFour}
                        </TableCell>
                        <TableCell
                          align='center'
                          sx={{
                            padding: "0px 0px",
                            color: "blue",
                            backgroundColor: "lightgray",
                          }}
                        >
                          {player.chc4}
                        </TableCell>

                        <TableCell>
                          {" "}
                          <Button
                            onClick={() => {
                              // sets input state if scores exist
                              handleOpen(player._id)
                              setId(player._id)
                              setDayOne(player.dayOne)
                              setDayTwo(player.dayTwo)
                              setDayThree(player.dayThree)
                              setDayFour(player.dayFour)
                            }}
                            disabled={!userInfo.isAdmin}
                          >
                            Edit
                          </Button>
                          {/* score input dialog box */}
                          <form
                            noValidate
                            autoComplete='off'
                            id='myForm'
                            onSubmit={submitHandler}
                          >
                            <Dialog
                              disablePortal
                              open={open[player._id]}
                              onClose={() => handleClose(player._id)}
                            >
                              <DialogContent>
                                <Grid
                                  container
                                  spacing={{ xs: 3, md: 4 }}
                                  justifyContent='flex-start'
                                  alignItems='center'
                                  direction='column'
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    sx={{ display: { xs: "none", md: "none" } }}
                                  >
                                    <FormControl>
                                      <InputLabel htmlFor='_id'>ID</InputLabel>
                                      <OutlinedInput
                                        style={{ width: 250 }}
                                        id='_id'
                                        value={_id}
                                        onChange={(e) => setId(e.target.value)}
                                        label='Id'
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={12}>
                                    <Stack direction='row' spacing={3}>
                                      <FormControl>
                                        <InputLabel htmlFor='dayone'>
                                          Round 1
                                        </InputLabel>
                                        <OutlinedInput
                                          type='number'
                                          style={{ width: 100 }}
                                          id='dayOne'
                                          value={dayOne}
                                          onChange={(e) =>
                                            setDayOne(e.target.value)
                                          }
                                          label='round 1'
                                        />
                                      </FormControl>
                                      <FormControl>
                                        <InputLabel htmlFor='daytwo'>
                                          Round 2
                                        </InputLabel>
                                        <OutlinedInput
                                          type='number'
                                          style={{ width: 100 }}
                                          id='dayTwo'
                                          value={dayTwo}
                                          onChange={(e) =>
                                            setDayTwo(e.target.value)
                                          }
                                          label='round 2'
                                        />
                                      </FormControl>
                                      <FormControl>
                                        <InputLabel htmlFor='daythree'>
                                          Round 3
                                        </InputLabel>
                                        <OutlinedInput
                                          type='number'
                                          style={{ width: 100 }}
                                          id='dayThree'
                                          value={dayThree}
                                          onChange={(e) =>
                                            setDayThree(e.target.value)
                                          }
                                          label='round 3'
                                        />
                                      </FormControl>
                                      <FormControl>
                                        <InputLabel htmlFor='dayfour'>
                                          Round 4
                                        </InputLabel>
                                        <OutlinedInput
                                          type='number'
                                          style={{ width: 100 }}
                                          id='dayFour'
                                          value={dayFour}
                                          onChange={(e) =>
                                            setDayFour(e.target.value)
                                          }
                                          label='round 4'
                                        />
                                      </FormControl>
                                    </Stack>
                                  </Grid>
                                </Grid>
                              </DialogContent>

                              <DialogActions>
                                <Grid item xs={12} sm={12} md={12}>
                                  <Stack direction='column' spacing={3}>
                                    <Button
                                      type='submit'
                                      variant='contained'
                                      form='myForm'
                                    >
                                      Submit
                                    </Button>
                                    <Button
                                      onClick={() => handleClose(player._id)}
                                      variant='contained'
                                    >
                                      Close
                                    </Button>
                                    {errorScoring && (
                                      <Alert severity='error'>
                                        {errorScoring}
                                      </Alert>
                                    )}
                                    {success && (
                                      <Alert severity='success'>
                                        Score Updated
                                      </Alert>
                                    )}
                                  </Stack>
                                </Grid>
                              </DialogActions>
                            </Dialog>
                          </form>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* displays only on mobile devices */}
          <MobileBasicLeaberboard cambo={cambo} />
        </>
      )}
    </>
  )
}

export default Leaderboard
