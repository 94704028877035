import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { listCourseDetails, updateCourse } from "../../actions/courseActions"
import { COURSE_UPDATE_RESET } from "../../constants/courseConstants"
import {
  OutlinedInput,
  InputLabel,
  Button,
  FormControl,
  Typography,
  Grid,
  LinearProgress,
  Alert,
  Box,
  Stack,
} from "@mui/material"

const EditCourse = () => {
  // react router dom v6 methods
  const navigate = useNavigate()
  const params = useParams()
  const courseId = params.id

  // redux
  const dispatch = useDispatch()

  //course details state from store.js
  const courseDetails = useSelector((state) => state.courseDetails)
  const { course } = courseDetails

  // edit course state from store.js
  const editCourse = useSelector((state) => state.editCourse)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = editCourse

  // component state
  const [clubName, setClubName] = useState("")
  const [courseName, setCourseName] = useState("")
  const [par, setPar] = useState(0)
  const [country, setCountry] = useState("")
  const [address, setAddress] = useState("")
  const [ratingBlack, setRatingBlack] = useState(0)
  const [ratingBlue, setRatingBlue] = useState(0)
  const [ratingWhite, setRatingWhite] = useState(0)
  const [ratingRed, setRatingRed] = useState(0)
  const [slopeBlack, setSlopeBlack] = useState(0)
  const [slopeBlue, setSlopeBlue] = useState(0)
  const [slopeWhite, setSlopeWhite] = useState(0)
  const [slopeRed, setSlopeRed] = useState(0)
  const [website, setWebsite] = useState("")

  // loads cambo details
  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: COURSE_UPDATE_RESET })
      navigate("/courselist")
    } else {
      if (course._id !== courseId) {
        dispatch(listCourseDetails(courseId))
      } else {
        setClubName(course.clubName)
        setCourseName(course.courseName)
        setPar(course.par)
        setCountry(course.country)
        setAddress(course.address)
        setRatingBlack(course.ratingBlack)
        setRatingBlue(course.ratingBlue)
        setRatingWhite(course.ratingWhite)
        setRatingRed(course.ratingRed)
        setSlopeBlack(course.slopeBlack)
        setSlopeBlue(course.slopeBlue)
        setSlopeWhite(course.slopeWhite)
        setSlopeRed(course.slopeRed)
        setWebsite(course.website)
      }
    }
  }, [dispatch, course, courseId, navigate, successUpdate])

  // submit edit handler
  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      updateCourse({
        _id: courseId,
        clubName,
        courseName,
        par,
        country,
        address,
        ratingBlack,
        ratingBlue,
        ratingWhite,
        ratingRed,
        slopeBlack,
        slopeBlue,
        slopeWhite,
        slopeRed,
        website,
      })
    )
  }

  return (
    <Box component='form' noValidate autoComplete='on'>
      <Grid
        container
        spacing={{ xs: 3, md: 4 }}
        justifyContent='flex-start'
        alignItems='center'
        direction='column'
      >
        {/* header label */}
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant='h2'>Edit Course Details</Typography>
        </Grid>

        {/* error / loading */}
        <Grid item xs={12} sm={12} md={12}>
          {errorUpdate && <Alert severity='error'>{errorUpdate}</Alert>}
          {loadingUpdate && <LinearProgress color='success' />}
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Stack direction='row' spacing={3}>
            {/* club name input */}
            <FormControl>
              <InputLabel htmlFor='clubname'>Club Name</InputLabel>
              <OutlinedInput
                id='clubname'
                value={clubName}
                onChange={(e) => setClubName(e.target.value)}
                label='Club Name'
              />
            </FormControl>

            {/* course name input */}
            <FormControl>
              <InputLabel htmlFor='coursename'>Course Name</InputLabel>
              <OutlinedInput
                id='coursename'
                value={courseName}
                onChange={(e) => setCourseName(e.target.value)}
                label='Course Name'
              />
            </FormControl>

            {/* country input */}
            <FormControl>
              <InputLabel htmlFor='country'>Country</InputLabel>
              <OutlinedInput
                id='country'
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                label='Country'
              />
            </FormControl>

            {/* address input */}
            <FormControl>
              <InputLabel htmlFor='address'>Address</InputLabel>
              <OutlinedInput
                id='address'
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                label='address'
              />
            </FormControl>
          </Stack>
        </Grid>

        {/* course par input */}
        <Grid item xs={12} sm={12} md={12}>
          <FormControl>
            <InputLabel htmlFor='par'>Par</InputLabel>
            <OutlinedInput
              style={{ width: 100 }}
              id='par'
              value={par}
              onChange={(e) => setPar(e.target.value)}
              label='par'
            />
          </FormControl>
        </Grid>

        {/* course rating label */}
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant='body2'>Course Rating</Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          {/* black tee course rating */}
          <Stack direction='row' spacing={3}>
            <FormControl>
              <InputLabel htmlFor='ratingblack'>Black</InputLabel>
              <OutlinedInput
                style={{ width: 100 }}
                id='ratingblack'
                value={ratingBlack}
                onChange={(e) => setRatingBlack(e.target.value)}
                label='Black'
              />
            </FormControl>

            {/* blue tee course rating */}
            <FormControl>
              <InputLabel htmlFor='ratingblue'>Blue</InputLabel>
              <OutlinedInput
                style={{ width: 100 }}
                id='ratingblue'
                value={ratingBlue}
                onChange={(e) => setRatingBlue(e.target.value)}
                label='Blue'
              />
            </FormControl>

            {/* white tee course rating */}
            <FormControl>
              <InputLabel htmlFor='ratingwhite'>White</InputLabel>
              <OutlinedInput
                style={{ width: 100 }}
                id='reatingwhite'
                value={ratingWhite}
                onChange={(e) => setRatingWhite(e.target.value)}
                label='White'
              />
            </FormControl>

            {/* red tee course rating */}
            <FormControl>
              <InputLabel htmlFor='ratingred'>Red</InputLabel>
              <OutlinedInput
                style={{ width: 100 }}
                id='reatingred'
                value={ratingRed}
                onChange={(e) => setRatingRed(e.target.value)}
                label='Red'
              />
            </FormControl>
          </Stack>
        </Grid>

        {/* slope rating header label */}
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant='body2'>Slope Rating</Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Stack direction='row' spacing={3}>
            {/* black tee slope rating */}
            <FormControl>
              <InputLabel htmlFor='slopeblack'>Black</InputLabel>
              <OutlinedInput
                style={{ width: 100 }}
                id='slopeblack'
                value={slopeBlack}
                onChange={(e) => setSlopeBlack(e.target.value)}
                label='Black'
              />
            </FormControl>

            {/* blue tee slope rating */}
            <FormControl>
              <InputLabel htmlFor='slopeblue'>Blue</InputLabel>
              <OutlinedInput
                style={{ width: 100 }}
                id='slopeblue'
                value={slopeBlue}
                onChange={(e) => setSlopeBlue(e.target.value)}
                label='Blue'
              />
            </FormControl>

            {/* white tee slope rating */}
            <FormControl>
              <InputLabel htmlFor='slopewhite'>White</InputLabel>
              <OutlinedInput
                style={{ width: 100 }}
                id='slopewhite'
                value={slopeWhite}
                onChange={(e) => setSlopeWhite(e.target.value)}
                label='White'
              />
            </FormControl>

            {/* red tee slope rating */}
            <FormControl>
              <InputLabel htmlFor='slopered'>Red</InputLabel>
              <OutlinedInput
                style={{ width: 100 }}
                id='slopered'
                value={slopeRed}
                onChange={(e) => setSlopeRed(e.target.value)}
                label='Red'
              />
            </FormControl>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          {/* course website */}
          <FormControl>
            <InputLabel htmlFor='website'>Website</InputLabel>
            <OutlinedInput
              id='website'
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
              label='website'
            />
          </FormControl>
        </Grid>

        {/* submit button */}
        <Grid item xs={12} sm={12} md={12}>
          <FormControl>
            <Button type='submit' onClick={submitHandler} variant='contained'>
              Submit
            </Button>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  )
}

export default EditCourse
