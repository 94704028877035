import React, { useEffect, useState } from "react"
import { Link as RouterLink } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { listCourses, createCourse } from "../../actions/courseActions"
import { COURSE_CREATE_RESET } from "../../constants/courseConstants"
import Autocomplete from "@mui/material/Autocomplete"
import CourseData from "../../components/courses/CourseData"
import {
  Grid,
  LinearProgress,
  Alert,
  FormControl,
  TextField,
  Button,
  Stack,
} from "@mui/material"

const CourseList = () => {
  // redux method
  const dispatch = useDispatch()

  // reacter router v6 methods
  const navigate = useNavigate()

  // course list  state from store
  const courseList = useSelector((state) => state.courseList)
  const { loading, error, courses } = courseList // destructuring

  // course state for course selection
  const [course, setCourse] = useState(null)

  // user state from store
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin // destructuring

  // course create state from store
  const courseCreate = useSelector((state) => state.courseCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    course: createdCourse,
  } = courseCreate

  // dispatch
  useEffect(() => {
    dispatch({ type: COURSE_CREATE_RESET })
    if (userInfo) {
      dispatch(listCourses())
    } else {
      navigate("/")
    }
    if (successCreate && userInfo) {
      navigate(`/admin/course/${createdCourse._id}/edit`)
    }
  }, [dispatch, navigate, userInfo, successCreate, createdCourse])

  // create new golf course
  const createCourseHandler = (course) => {
    dispatch(createCourse())
  }

  return (
    <Grid
      container
      spacing={{ xs: 2, md: 2 }}
      justifyContent='center'
      alignItems='center'
      direction='column'
    >
      {/* go back / add golf course buttons */}
      <Grid item xs={12}>
        <Stack direction='row' spacing={3}>
          <Button
            size='small'
            variant='contained'
            component={RouterLink}
            to='/'
          >
            Back
          </Button>
          <Button
            size='small'
            variant='contained'
            onClick={createCourseHandler}
          >
            Add Course
          </Button>
          {/* loading / error */}
          {loadingCreate && <LinearProgress color='success' />}
          {errorCreate && <Alert severity='error'>{errorCreate}</Alert>}
        </Stack>
      </Grid>

      {/* list courses out in select in aplha oroder */}
      <Grid item xs={12} sm={12} md={12}>
        <FormControl>
          <Autocomplete
            disablePortal
            options={courses}
            getOptionLabel={(option) =>
              option.clubName + " " + option.courseName
            }
            isOptionEqualToValue={(option, value) =>
              option.clubName === value.clubName &&
              option.courseName === value.courseName &&
              option.ratingWhite === value.ratingWhite &&
              option.slopeWhite === value.slopeWhite &&
              option.par === value.par
            }
            id='course'
            value={course}
            onChange={(event, newValue) => {
              setCourse(newValue)
            }}
            sx={{ width: 250, marginTop: 5 }}
            renderInput={(params) => <TextField {...params} label='Course' />}
          />
        </FormControl>
      </Grid>

      {/* course data component */}
      <Grid item xs={12}>
        {loading ? (
          <LinearProgress severity='success' />
        ) : error ? (
          <Alert severity='error'>{error}</Alert>
        ) : (
          <>
            <Grid item xs={12}>
              <CourseData course={course} />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  )
}

export default CourseList
