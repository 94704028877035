import React from "react"
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material"

const DailyHandicaps = ({ cambo }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 100 }} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Day 1</TableCell>
            <TableCell>Day 2</TableCell>
            <TableCell>Day 3</TableCell>
            <TableCell>Day 4</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cambo.players.map((player) => (
            <TableRow>
              <TableCell>
                {player.firstName} {player.lastName}
              </TableCell>
              <TableCell>{player.chc1.toFixed(0)}</TableCell>
              <TableCell>{player.chc2.toFixed(0)}</TableCell>
              <TableCell>{player.chc3.toFixed(0)}</TableCell>
              <TableCell>{player.chc4.toFixed(0)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default DailyHandicaps
