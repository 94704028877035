import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link as RouterLink } from "react-router-dom"
import { useNavigate, useParams } from "react-router-dom"
import {
  getUserDetails,
  editUser,
  // generateHandicap,
} from "../../actions/userActions"
import { generateHandicapOptions } from "../../utils/handicaps"
// import handicaps from "../../utils/handicaps.js"
import countries from "../../utils/countries"
import { USER_EDIT_RESET } from "../../constants/userConstants"
import {
  OutlinedInput,
  InputLabel,
  Button,
  FormControl,
  Typography,
  Grid,
  LinearProgress,
  Alert,
  Switch,
  FormControlLabel,
  Box,
  Select,
  MenuItem,
} from "@mui/material"
const UserEdit = () => {
  // react router dom v6 methods
  const navigate = useNavigate()
  const params = useParams()
  const userId = params.id

  const handicaps = generateHandicapOptions()

  // redux
  const dispatch = useDispatch()

  // user details state from store.js
  const userDetails = useSelector((state) => state.userDetails)
  const { loading, user, error } = userDetails // deconstruct reducer

  // const calculateHandicap = useSelector((state) => state.calculateHandicap)
  // const { loading: loadHandicap, error: errorHandicap } = calculateHandicap

  // edit user state from store.js
  const userEdit = useSelector((state) => state.userEdit)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = userEdit // deconstruct reducer

  // components state
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [isAdmin, setIsAdmin] = useState(false)
  const [handicap, setHandicap] = useState(0)
  const [password, setPassword] = useState("")
  const [password1, setPassword1] = useState("")
  const [country, setCountry] = useState("")

  // dispatch
  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: USER_EDIT_RESET })
      navigate("/admin/useradmin")
    } else {
      if (!user.firstName || user._id !== userId) {
        dispatch(getUserDetails(userId))
      } else {
        setFirstName(user.firstName)
        setLastName(user.lastName)
        setEmail(user.email)
        setIsAdmin(user.isAdmin)
        setHandicap(user.handicap)
        setCountry(user.country)
      }
    }
  }, [
    dispatch,
    userId,
    user._id,
    user.email,
    user.isAdmin,
    user.firstName,
    user.lastName,
    user.handicap,
    user.country,
    successUpdate,
    navigate,
  ])

  // submit edit user handler
  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      editUser({
        _id: userId,
        firstName,
        lastName,
        email,
        isAdmin,
        handicap,
        password,
        country,
      })
    )
  }

  // const newHandicap = (e) => {
  //   e.preventDefault()
  //   console.log(userId)
  //   dispatch(generateHandicap({ _id: userId }))
  // }

  return (
    <Box component='form' noValidate autoComplete='off'>
      <Grid
        container
        spacing={{ xs: 3, md: 4 }}
        justifyContent='flex-start'
        alignItems='center'
        direction='column'
      >
        <Grid item xs={12}>
          {/* Return to user list */}
          <Button
            variant='contained'
            size='large'
            component={RouterLink}
            to='/admin/useradmin'
          >
            Go Back
          </Button>
        </Grid>

        {/* header label */}
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant='h1'>Edit User</Typography>
        </Grid>

        {/* checking for update erros or laoding */}
        <Grid item xs={12} sm={12} md={12}>
          {loadingUpdate && <LinearProgress color='success' />}
          {/* {loadHandicap && <LinearProgress color='success' />} */}
          {errorUpdate && <Alert severity='error'>{errorUpdate}</Alert>}
          {/* {errorHandicap && <Alert severity='error'>{errorHandicap}</Alert>} */}
        </Grid>

        {loading ? (
          <LinearProgress color='success' /> // loading
        ) : error ? (
          <Alert severity='error' /> // sever error
        ) : (
          <>
            {/* first name input */}
            <Grid item xs={12} sm={12} md={12}>
              <FormControl>
                <InputLabel htmlFor='firstName'>First Name</InputLabel>
                <OutlinedInput
                  id='firstname'
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  label='First Name'
                />
              </FormControl>
            </Grid>

            {/* last name inout */}
            <Grid item xs={12} sm={12} md={12}>
              <FormControl>
                <InputLabel htmlFor='lastName'>Last Name</InputLabel>
                <OutlinedInput
                  id='lastname'
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  label='Last Name'
                />
              </FormControl>
            </Grid>

            {/* email address input */}
            <Grid item xs={12} sm={12} md={12}>
              <FormControl>
                <InputLabel htmlFor='email'>Email</InputLabel>
                <OutlinedInput
                  type='email'
                  id='email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  label='Email'
                />
              </FormControl>
            </Grid>

            {/* handicap input */}
            <Grid item xs={12} sm={12} md={12}>
              <FormControl>
                <InputLabel htmlFor='handicap'>Handicap</InputLabel>
                <Select
                  style={{ width: 80, height: 40 }}
                  labelId='handicap'
                  id='handicap'
                  value={handicap}
                  label='Handicap'
                  onChange={(e) => setHandicap(e.target.value)}
                >
                  {handicaps?.map((handicap) => {
                    return (
                      <MenuItem key={handicap.value} value={handicap.value}>
                        {handicap.label ?? handicap.value}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>

            {/* country input */}
            <Grid item xs={12} sm={12} md={12}>
              <FormControl>
                <InputLabel htmlFor='country'>Country</InputLabel>
                <Select
                  style={{ width: 100, height: 40 }}
                  labelId='country'
                  id='country'
                  value={country}
                  label='country'
                  onChange={(e) => setCountry(e.target.value)}
                >
                  {countries?.map((country) => {
                    return (
                      <MenuItem key={country.value} value={country.value}>
                        {country.label ?? country.value}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>

            {/* password input */}
            <Grid item xs={12} sm={12} md={12}>
              <FormControl>
                <InputLabel htmlFor='password'>Password</InputLabel>
                <OutlinedInput
                  id='password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  label='Password'
                  type='password'
                />
              </FormControl>
            </Grid>

            {/* confirm password input */}
            <Grid item xs={12} sm={12} md={12}>
              <FormControl>
                <InputLabel htmlFor='password'>Confirm Password</InputLabel>
                <OutlinedInput
                  id='password1'
                  value={password1}
                  onChange={(e) => setPassword1(e.target.value)}
                  label='Confirm Password'
                  type='password'
                />
              </FormControl>
            </Grid>

            {/* isAdmin input */}
            <Grid item xs={12} sm={12} md={12}>
              <FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      id='isAdmin'
                      type='checkbox'
                      checked={isAdmin}
                      onChange={(e) => setIsAdmin(e.target.checked)}
                      inputProps={{ "aria-label": "Is Admin" }}
                    />
                  }
                  label='Admin'
                />
              </FormControl>
            </Grid>

            {/* submit button */}
            <Grid item xs={12} sm={12} md={12}>
              <FormControl>
                <Button
                  type='submit'
                  style={{ width: "100%" }}
                  onClick={submitHandler}
                  variant='contained'
                >
                  Update
                </Button>
              </FormControl>
            </Grid>

            {/* generate handicap */}
            {/* <Grid item xs={12} sm={12} md={12}>
              <FormControl>
                <Button
                  type='submit'
                  style={{ width: "100%" }}
                  onClick={newHandicap}
                  variant='contained'
                >
                  Update Handicap
                </Button>
              </FormControl>
            </Grid> */}
          </>
        )}
      </Grid>
    </Box>
  )
}

export default UserEdit
