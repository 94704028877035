//cambo list constants
export const CAMBO_LIST_REQUEST = "CAMBO_LIST_REQUEST"
export const CAMBO_LIST_SUCCESS = "CAMBO_LIST_SUCCESS"
export const CAMBO_LIST_FAIL = "CAMBO_LIST_FAIL"

// Single cambo cup constants used in reducers
export const CAMBO_DETAILS_REQUEST = "CAMBO_DETAILS_REQUEST"
export const CAMBO_DETAILS_SUCCESS = "CAMBO_DETAILS_SUCCESS"
export const CAMBO_DETAILS_FAIL = "CAMBO_DETAILS_FAIL"

// add player constants used in reducers
export const CAMBO_ADDPLAYER_REQUEST = "CAMBO_ADDPLAYER_REQUEST"
export const CAMBO_ADDPLAYER_SUCCESS = "CAMBO_ADDPLAYER_SUCCESS"
export const CAMBO_ADDPLAYER_FAIL = "CAMBO_ADDPLAYER_FAIL"
export const CAMBO_ADDPLAYER_RESET = "CAMBO_ADDPLAYER_RESET"

// create cambo constants used in reducers
export const CAMBO_CREATE_REQUEST = "CAMBO_CREATE_REQUEST"
export const CAMBO_CREATE_SUCCESS = "CAMBO_CREATE_SUCCESS"
export const CAMBO_CREATE_FAIL = "CAMBO_CREATE_FAIL"
export const CAMBO_CREATE_RESET = "CAMBO_CREATE_RESET"

// cambo update constants used in reducers
export const CAMBO_UPDATE_REQUEST = "CAMBO_UPDATE_REQUEST"
export const CAMBO_UPDATE_SUCCESS = "CAMBO_UPDATE_SUCCESS"
export const CAMBO_UPDATE_FAIL = "CAMBO_UPDATE_FAIL"
export const CAMBO_UPDATE_RESET = "CAMBO_UPDATE_RESET"

// cambo scoring update costants
export const CAMBO_SCORING_UPDATE_REQUEST = "CAMBO_SCORING_UPDATE_REQUEST"
export const CAMBO_SCORING_UPDATE_SUCCESS = "CAMBO_SCORING_UPDATE_SUCCESS"
export const CAMBO_SCORING_UPDATE_FAIL = "CAMBO_SCORING_UPDATE_FAIL"
export const CAMBO_SCORING_UPDATE_RESET = "CAMBO_SCORING_UPDATE_RESET"

// player flights update constants
export const CAMBO_FLIGHTS_UPDATE_REQUEST = "CAMBO_FLIGHTS_UPDATE_REQUES"
export const CAMBO_FLIGHTS_UPDATE_SUCCESS = "CAMBO_FLIGHTS_UPDATE_SUCCESS"
export const CAMBO_FLIGHTS_UPDATE_FAIL = "CAMBO_FLIGHTS_UPDATE_FAIL"
export const CAMBO_FLIGHTS_UPDATE_RESET = "CAMBO_FLIGHTS_UPDATE_RESET"

// constants to delete a single cambo event (admin only)
export const CAMBO_DELETE_REQUEST = "CAMBO_DELETE_REQUEST"
export const CAMBO_DELETE_SUCCESS = "CAMBO_DELETE_SUCCESS"
export const CAMBO_DELETE_FAIL = "CAMBO_DELETE_FAIL"

// constants to sort players into groups
export const CAMBO_SORTPLAYER_REQUEST = "CAMBO_SORTPLAYER_REQUEST"
export const CAMBO_SORTPLAYER_SUCCESS = "CAMBO_SORTPLAYER_SUCCESS"
export const CAMBO_SORTPLAYER_FAIL = "CAMBO_SORTPLAYER_FAIL"

// constants to delete a single player cambo event (admin only)
export const CAMBO_PLAYER_DELETE_REQUEST = "CAMBO_PLAYER_DELETE_REQUEST"
export const CAMBO_PLAYER_DELETE_SUCCESS = "CAMBO_PLAYER_DELETE_SUCCESS"
export const CAMBO_PLAYER_DELETE_FAIL = "CAMBO_PLAYER_DELETE_FAIL"
export const CAMBO_PLAYER_DELETE_RESET = "CAMBO_PLAYER_DELETE_RESET"
