import React from "react"
import { Link as RouterLink } from "react-router-dom"
import {
  Grid,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material"

const TigerList = ({ tigers, deleteTigerHandler }) => {
  // format date data
  const formatDate = (date) => {
    const d = new Date(date)
    const yyyy = d.getFullYear()
    return `${yyyy}`
  }
  return (
    <Grid item xs={12} style={{ marginTop: 20 }}>
      {/* event list table */}
      <TableContainer
        sx={{ display: { xs: "none", md: "block" } }}
        component={Paper}
      >
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Year</TableCell>
              <TableCell>Players</TableCell>
              <TableCell>Live</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {tigers
              .filter((tiger) => tiger.archived === false)
              .map((tiger) => (
                <TableRow key={tiger._id}>
                  <TableCell
                    style={{ textDecoration: "none", color: "inherit" }}
                    component={RouterLink}
                    to={`/admin/tiger/${tiger._id}/edit`}
                  >
                    {formatDate(tiger.year)}{" "}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {tiger.players.length}
                  </TableCell>
                  <TableCell>
                    {tiger.live ? <CircularProgress color='success' /> : null}
                  </TableCell>
                  <TableCell>
                    <Button
                      style={{ color: "#FF0000" }}
                      size='small'
                      onClick={() => deleteTigerHandler(tiger._id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* mobile screen event list table */}
      <TableContainer
        sx={{ display: { xs: "block", md: "none" } }}
        component={Paper}
      >
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell>Year</TableCell>
              <TableCell>Live</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {tigers
              .filter((tiger) => tiger.archived === false)
              .map((tiger) => (
                <TableRow key={tiger._id}>
                  <TableCell
                    style={{ textDecoration: "none", color: "inherit" }}
                    component={RouterLink}
                    to={`/admin/tiger/${tiger._id}/edit`}
                  >
                    {formatDate(tiger.year)}
                  </TableCell>
                  <TableCell>
                    {tiger.live ? <CircularProgress color='success' /> : null}
                  </TableCell>
                  <TableCell>
                    <Button
                      style={{ color: "#FF0000" }}
                      size='small'
                      onClick={() => deleteTigerHandler(tiger._id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  )
}

export default TigerList
