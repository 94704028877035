// course course list constants
export const COURSE_LIST_REQUEST = "COURSE_LIST_REQUEST"
export const COURSE_LIST_SUCCESS = "COURSE_LIST_SUCCESS"
export const COURSE_LIST_FAIL = "COURSE_LIST_FAIL"

// create course constants used in reducers
export const COURSE_CREATE_REQUEST = "COURSE_CREATE_REQUEST"
export const COURSE_CREATE_SUCCESS = "COURSE_CREATE_SUCCESS"
export const COURSE_CREATE_FAIL = "COURSE_CREATE_FAIL"
export const COURSE_CREATE_RESET = "COURSE_CREATE_RESET"

// course update constants used in reducers
export const COURSE_UPDATE_REQUEST = "COURSE_UPDATE_REQUEST"
export const COURSE_UPDATE_SUCCESS = "COURSE_UPDATE_SUCCESS"
export const COURSE_UPDATE_FAIL = "COURSE_UPDATE_FAIL"
export const COURSE_UPDATE_RESET = "COURSE_UPDATE_RESET"

// Single course constants used in reducers
export const COURSE_DETAILS_REQUEST = "COURSE_DETAILS_REQUEST"
export const COURSE_DETAILS_SUCCESS = "COURSE_DETAILS_SUCCESS"
export const COURSE_DETAILS_FAIL = "COURSE_DETAILS_FAIL"
export const COURSE_DETAILS_RESET = "COURSE_DETAILS_RESET"

// constants to delete a single course (admin only)
export const COURSE_DELETE_REQUEST = "COURSE_DELETE_REQUEST"
export const COURSE_DELETE_SUCCESS = "COURSE_DELETE_SUCCESS"
export const COURSE_DELETE_FAIL = "COURSE_DELETE_FAIL"
