import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link as RouterLink, Link } from "react-router-dom"
import { logout } from "../../actions/userActions"
import AppBar from "@mui/material/AppBar"
import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import MenuItem from "@mui/material/MenuItem"
import Typography from "@mui/material/Typography"
import PersonIcon from "@mui/icons-material/Person"
import LogoutIcon from "@mui/icons-material/Logout"
import GolfCourseIcon from "@mui/icons-material/GolfCourse"
import Menu from "@mui/material/Menu"
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings"
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents"
import GroupIcon from "@mui/icons-material/Group"
import SportsGolfIcon from "@mui/icons-material/SportsGolf"
import ArchiveIcon from "@mui/icons-material/Archive"
import PetsIcon from "@mui/icons-material/Pets"
import { Button } from "@mui/material"

const Header = () => {
  // dropdown menu state management for user
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const openMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  // react router dom v6 methods
  const dispatch = useDispatch()

  // dropdown menu state management for admin
  const [anchorE2, setAnchorE2] = useState(null)
  const open2 = Boolean(anchorE2)
  const openMenu2 = (event) => {
    setAnchorE2(event.currentTarget)
  }
  const handleClose2 = () => {
    setAnchorE2(null)
  }

  //login state from store.js
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin // deconstruct reducer

  // logout action
  const logoutHandler = () => {
    dispatch(logout())
  }
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          style={{
            top: 0,
            left: 0,
            margin: 0,
            border: "none",
            boxShadow: "none",
          }}
        >
          <Toolbar>
            <Typography
              variant='h6'
              component='div'
              sx={{ flexGrow: 1, display: { xs: "none", md: "block" } }}
            >
              <Link
                to='/'
                component={RouterLink}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                The Cambo Cup
              </Link>
            </Typography>

            {/* conditional rendering on login/profile button */}
            {userInfo ? (
              <>
                {/* displays button and name on larger screens */}
                <Typography sx={{ flexGrow: 1 }} component='div'>
                  <Link
                    to='/'
                    component={RouterLink}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    Handicap: {userInfo.handicap}
                  </Link>
                </Typography>

                <Button
                  sx={{ display: { xs: "none", md: "block" } }}
                  color='inherit'
                  aria-controls={open ? "positioned-menu" : undefined}
                  aria-haspopup='true'
                  aria-expanded={open ? "true" : undefined}
                  onClick={openMenu}
                  id='username'
                >
                  {userInfo.firstName} {userInfo.lastName}
                </Button>

                {/* displays icon button only on smaller screens */}
                <Button
                  sx={{ display: { xs: "block", md: "none" } }}
                  color='inherit'
                  aria-controls={open ? "positioned-menu" : undefined}
                  aria-haspopup='true'
                  aria-expanded={open ? "true" : undefined}
                  onClick={openMenu}
                  id='username'
                >
                  <PersonIcon fontSize='large' />
                </Button>

                {/* menu items for dropdowns */}
                <Menu
                  id='positioned-menu'
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <MenuItem
                    component={RouterLink}
                    to='/userprofile'
                    onClick={handleClose}
                  >
                    <PersonIcon />
                    Profile
                  </MenuItem>
                  <MenuItem
                    component={RouterLink}
                    to='/userprofile/scoring'
                    onClick={handleClose}
                  >
                    <SportsGolfIcon />
                    Scoring
                  </MenuItem>
                  <MenuItem onClick={logoutHandler}>
                    <LogoutIcon />
                    Logout
                  </MenuItem>
                </Menu>
              </>
            ) : null}

            {/* admin panel */}
            {userInfo && userInfo.isAdmin && (
              <>
                {/* menu button */}
                <Button
                  color='inherit'
                  aria-controls={open ? "positioned-menu-admin" : undefined}
                  aria-haspopup='true'
                  aria-expanded={open2 ? "true" : undefined}
                  onClick={openMenu2}
                  id='adminMenu'
                >
                  <AdminPanelSettingsIcon />
                  Admin
                </Button>
                <Menu
                  id='positioned-menu-admin'
                  anchorEl={anchorE2}
                  open={open2}
                  onClose={handleClose2}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  {/* user admin */}
                  <MenuItem
                    component={RouterLink}
                    to='/admin/useradmin'
                    onClick={handleClose2}
                  >
                    <GroupIcon />
                    Users
                  </MenuItem>
                  {/* cambo cup admin */}
                  <MenuItem
                    component={RouterLink}
                    to='/admin/camboadmin'
                    onClick={handleClose2}
                  >
                    <EmojiEventsIcon />
                    Cambo Cups
                  </MenuItem>
                  {/* race to tiger admin */}
                  <MenuItem
                    component={RouterLink}
                    to='/admin/tiger'
                    onClick={handleClose2}
                  >
                    <PetsIcon />
                    Race To Tiger
                  </MenuItem>
                  {/* archivbed cambo cup admin */}
                  <MenuItem
                    component={RouterLink}
                    to='/admin/camboadmin/archived'
                    onClick={handleClose2}
                  >
                    <ArchiveIcon />
                    Archived Cambo Cups
                  </MenuItem>
                  {/* golf course admin */}
                  <MenuItem
                    component={RouterLink}
                    to='/admin/courseadmin'
                    onClick={handleClose2}
                  >
                    <GolfCourseIcon />
                    Courses
                  </MenuItem>
                </Menu>
              </>
            )}
          </Toolbar>
        </AppBar>
      </Box>
    </>
  )
}

export default Header
