import React from "react"
import GolfLogo from "../../../images/redMountain.jpg"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import { Button, Typography } from "@mui/material"
import { Link as RouterLink } from "react-router-dom"

const GolfCourses = () => {
  return (
    <Card sx={{ width: 345 }}>
      <CardMedia
        component='img'
        height='250'
        src={GolfLogo}
        alt='Golf Courses'
      />
      <CardContent>
        <Typography gutterBottom variant='h5' component='div'>
          Golf Courses
        </Typography>
      </CardContent>
      <CardActions>
        <Button component={RouterLink} to='/courselist' size='small'>
          Browse Courses
        </Button>
      </CardActions>
    </Card>
  )
}

export default GolfCourses
