import React from "react"
import { Typography } from "@mui/material"
import CamboLogo from "../../../images/camboLogo.png"

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    width: "300px",
  },
  image: {
    width: "150px",
    height: "100px",
  },
  heading: {
    fontFamily: "Indie Flower",
    fontSize: "32px",
  },
}

const HeaderText = ({ cambo }) => {
  return (
    <div style={styles.container}>
      <img src={CamboLogo} alt='logo' style={styles.image} />
      <Typography variant='h2' style={styles.heading}>
        {cambo.country}
      </Typography>
    </div>
  )
}

export default HeaderText
