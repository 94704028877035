import React from "react"
import { Box, Grid, Typography } from "@mui/material"

const Footer = () => {
  return (
    // Simple Copyright Footer
    <footer>
      <Box>
        <Grid
          container
          spacing={0}
          direction='column'
          alignItems='center'
          justifyContent='center'
        >
          <Grid item xs={12}>
            <Typography
              variant='subtitle1'
              gutterBottom
              component='div'
              style={{ marginTop: 20 }}
            >
              Copyright &copy; Joshua Gagnuss. All rights reserved.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </footer>
  )
}

export default Footer
