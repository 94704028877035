import axios from "axios"
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_RESET,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_EDIT_REQUEST,
  USER_EDIT_SUCCESS,
  USER_EDIT_FAIL,
  USER_SCORING_ADD_REQUEST,
  USER_SCORING_ADD_SUCCESS,
  USER_SCORING_ADD_FAIL,
  USER_CALHANDICAP_REQUEST,
  USER_CALHANDICAP_SUCCESS,
  USER_CALHANDICAP_FAIL,
} from "../constants/userConstants"

// login action to handle login request/Success/Fail
export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST })

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    }

    const { data } = await axios.post(
      "/api/users/login",
      { email, password },
      config
    )

    dispatch({ type: USER_LOGIN_SUCCESS, payload: data })
    localStorage.setItem("userInfo", JSON.stringify(data))
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// user logout action
export const logout = () => (dispatch) => {
  dispatch({ type: USER_LOGOUT })
  localStorage.removeItem("userInfo")
  document.location.href = "/"
}

// user register action
export const register =
  (firstName, lastName, email, password, country) => async (dispatch) => {
    try {
      dispatch({ type: USER_REGISTER_REQUEST }) // register request

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      }

      const { data } = await axios.post(
        "/api/users",
        { firstName, lastName, email, password, country },
        config
      )

      dispatch({ type: USER_REGISTER_SUCCESS, payload: data }) //register
      dispatch({ type: USER_LOGIN_SUCCESS, payload: data }) //login after register
      localStorage.setItem("userInfo", JSON.stringify(data)) //set userInfo
    } catch (error) {
      dispatch({
        type: USER_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

// get user details by id or profile
export const getUserDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_DETAILS_REQUEST }) // details request

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/users/${id}`, config)

    dispatch({ type: USER_DETAILS_SUCCESS, payload: data }) //details success
  } catch (error) {
    dispatch({
      type: USER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// update user  profile
export const updateUserDetails = (user) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_UPDATE_REQUEST }) // update request

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(`/api/users/profile`, user, config)

    dispatch({ type: USER_UPDATE_SUCCESS, payload: data }) //update success
    dispatch({ type: USER_LOGIN_SUCCESS, payload: data }) //login success
    localStorage.setItem("userInfo", JSON.stringify(data)) //set userInfo
  } catch (error) {
    dispatch({
      type: USER_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// list user  profiles for admins
export const listUsers = () => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_LIST_REQUEST }) // LIST request

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/users`, config)

    dispatch({ type: USER_LIST_SUCCESS, payload: data }) //LIST success
  } catch (error) {
    dispatch({
      type: USER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// delete a user (admin only)
export const deleteUser = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_DELETE_REQUEST }) // DELETE request

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`/api/users/${id}`, config)

    dispatch({ type: USER_DELETE_SUCCESS }) //DELETE success
  } catch (error) {
    dispatch({
      type: USER_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// edit a user (admin only)
export const editUser = (user) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_EDIT_REQUEST }) // EDIT request

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(`/api/users/${user._id}`, user, config)

    dispatch({ type: USER_EDIT_SUCCESS }) //EDIT success
    dispatch({ type: USER_DETAILS_SUCCESS, payload: data }) //details success
    dispatch({ type: USER_DETAILS_RESET }) //details reset
  } catch (error) {
    dispatch({
      type: USER_EDIT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// add user scores
export const updateUserScore = (user) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_SCORING_ADD_REQUEST }) // update request

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`/api/users/profile`, user, config)
    dispatch({ type: USER_SCORING_ADD_SUCCESS, payload: data })
    dispatch({ type: USER_LOGIN_SUCCESS, payload: data }) //login success
    localStorage.setItem("userInfo", JSON.stringify(data)) //set userInfo
  } catch (error) {
    dispatch({
      type: USER_SCORING_ADD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// add user scores
export const generateHandicap = (user) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_CALHANDICAP_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(
      `/api/users/${user._id}/handicap`,
      user,
      config
    )
    dispatch({ type: USER_CALHANDICAP_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: USER_CALHANDICAP_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
