// contants to create race to tiger event
export const TIGER_CREATE_REQUEST = "TIGER_CREATE_SUCESS"
export const TIGER_CREATE_SUCCESS = "TIGER_CREATE_SUCCESS"
export const TIGER_CREATE_FAIL = "TIGER_CREATE_FAIL"
export const TIGER_CREATE_RESET = "TIGER_CREATE_RESET"

// constants to list tiger events
export const TIGER_LIST_REQUEST = "TIGER_LIST_REQUEST"
export const TIGER_LIST_SUCCESS = "TIGER_LIST_SUCCESS"
export const TIGER_LIST_FAIL = "TIGER_LIST_FAIL"

// constant to list tiger event details
export const TIGER_DETAILS_REQUEST = "TIGER_DETAILS_REQUEST"
export const TIGER_DETAILS_SUCCESS = "TIGER_DETAILS_SUCCESS"
export const TIGER_DETAILS_FAIL = "TIGER_DETAILS_FAIL"

// constants to delete tiger events
export const TIGER_DELETE_REQUEST = "TIGER_DELETE_REQUEST"
export const TIGER_DELETE_SUCCESS = "TIGER_DELETE_SUCCESS"
export const TIGER_DELETE_FAIL = "TIGER_DELETE_FAIL"
export const TIGER_DELETE_RESET = "TIGER_DELETE_RESET"

// constants to edit tiger events
export const TIGER_UPDATE_REQUEST = "TIGER_UPDATE_REQUEST"
export const TIGER_UPDATE_SUCCESS = "TIGER_UPDATE_SUCCESS"
export const TIGER_UPDATE_FAIL = "TIGER_UPDATE_FAIL"
export const TIGER_UPDATE_RESET = "TIGER_UPDATE_RESET"

// sync scores with last cambo cup event
export const TIGER_SYNC_REQUEST = "TIGER_SYNC_REQUEST"
export const TIGER_SYNC_SUCCESS = "TIGER_SYNC_SUCCESS"
export const TIGER_SYNC_FAIL = "TIGER_SYNC_FAIL"
