import React from "react"
import {
  Button,
  Alert,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@mui/material"

const SignupDialog = ({
  handleSubmit,
  arrFlight,
  arrDate,
  depFlight,
  depDate,
  fullName,
  passportNumber,
  passportIssue,
  passportExpiry,
  dob,
  userInfo,
  cambo,
  errorFlights,
  errorPlayer,
  open,
  setFullName,
  setArrDate,
  setArrFlight,
  setDepDate,
  setDepFlight,
  setPassportExpiry,
  setPassportIssue,
  setPassportNumber,
  setDOB,
  setOpen,
  successPlayer,
  handleClickOpen,
  handleClose,
  loadingPlayer,
}) => {
  return (
    <>
      {cambo.batam ? (
        // *! dialog box for batam events
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>
            {userInfo.firstName} {userInfo.lastName}
          </DialogTitle>
          <DialogContent>
            <DialogContentText style={{ marginBottom: 20 }}>
              Please enter full passport details for Batam event
            </DialogContentText>

            <Stack direction='column' spacing={3}>
              {/* full Name */}
              <FormControl>
                <InputLabel htmlFor='fullname'>Full Name - Passport</InputLabel>
                <OutlinedInput
                  style={{ width: 350 }}
                  id='arrdate'
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  label='arrdate'
                />
              </FormControl>

              {/* passport number */}
              <FormControl>
                <InputLabel htmlFor='passportnumber'>
                  Passport Number
                </InputLabel>
                <OutlinedInput
                  style={{ width: 350 }}
                  id='passportnumber'
                  value={passportNumber}
                  onChange={(e) => setPassportNumber(e.target.value)}
                  label='passportnumber'
                />
              </FormControl>

              {/* passport issue date */}
              <FormControl>
                <InputLabel htmlFor='passportissue'>Issue Date</InputLabel>
                <OutlinedInput
                  id='passport issue'
                  value={passportIssue}
                  onChange={(e) => setPassportIssue(e.target.value)}
                  label='Issue Date'
                />
              </FormControl>

              {/* passport expiry date */}
              <FormControl>
                <InputLabel htmlFor='passportexpiry'>Expiry Date</InputLabel>
                <OutlinedInput
                  id='passport expiry'
                  value={passportExpiry}
                  onChange={(e) => setPassportExpiry(e.target.value)}
                  label='Issue Date'
                />
              </FormControl>

              {/* passport date of birth*/}
              <FormControl>
                <InputLabel htmlFor='dob'>D.O.B</InputLabel>
                <OutlinedInput
                  id='dob'
                  value={dob}
                  onChange={(e) => setDOB(e.target.value)}
                  label='D.O.B'
                />
              </FormControl>

              {/* arrival date */}
              <FormControl>
                <InputLabel htmlFor='arrDate'>
                  Arrival Date: dd.mm.yyyy
                </InputLabel>
                <OutlinedInput
                  id='arrdate'
                  value={arrDate}
                  onChange={(e) => setArrDate(e.target.value)}
                  label='arrdate'
                />
              </FormControl>

              {/* arrival ferry */}
              <FormControl>
                <InputLabel htmlFor='arrFlight'>Arrival Ferry No.</InputLabel>
                <OutlinedInput
                  // style={{ width: 100 }}
                  id='arrflight'
                  value={arrFlight}
                  onChange={(e) => setArrFlight(e.target.value)}
                  label='arrflight'
                />
              </FormControl>

              {/* departure date */}
              <FormControl>
                <InputLabel htmlFor='depDate'>
                  Departure Date: dd.mm.yyyy
                </InputLabel>
                <OutlinedInput
                  id='depdate'
                  value={depDate}
                  onChange={(e) => setDepDate(e.target.value)}
                  label='depdate'
                />
              </FormControl>

              {/* departure ferry */}
              <FormControl>
                <InputLabel htmlFor='depFlight'>Departure Ferry No.</InputLabel>
                <OutlinedInput
                  // style={{ width: 100 }}
                  id='depflight'
                  value={depFlight}
                  onChange={(e) => setDepFlight(e.target.value)}
                  label='depflight'
                />
              </FormControl>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button variant='contained' onClick={handleClose}>
              Close
            </Button>
            <Button variant='contained' onClick={handleSubmit}>
              Submit
            </Button>
          </DialogActions>
          {errorFlights && <Alert severity='error'>{errorFlights}</Alert>}
          {/* error handling for joinging event */}
          {loadingPlayer && (
            <Alert severity='success'>You have joined {cambo.name}</Alert>
          )}
          {errorPlayer && <Alert severity='error'>{errorPlayer}</Alert>}
          {successPlayer && (
            <Alert severity='success'>You have joined {cambo.name}</Alert>
          )}
        </Dialog>
      ) : (
        // *! dialog for events that are not in batam
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>
            {userInfo.firstName} {userInfo.lastName}
          </DialogTitle>
          <DialogContent>
            <DialogContentText style={{ marginBottom: 20 }}>
              Please enter arrival and departure information
            </DialogContentText>

            <Stack direction='column' spacing={3}>
              <Stack direction='row' spacing={3}>
                {/* arrival flight */}
                <FormControl>
                  <InputLabel htmlFor='arrFlight'>Arrival Flight</InputLabel>
                  <OutlinedInput
                    // style={{ width: 100 }}
                    id='arrflight'
                    value={arrFlight}
                    onChange={(e) => setArrFlight(e.target.value)}
                    label='arrflight'
                  />
                </FormControl>

                {/* arrival date */}
                <FormControl>
                  <InputLabel htmlFor='arrDate'>
                    Arrival Date: dd.mm.yyyy
                  </InputLabel>
                  <OutlinedInput
                    // style={{ width: 100 }}
                    id='arrdate'
                    value={arrDate}
                    onChange={(e) => setArrDate(e.target.value)}
                    label='arrdate'
                  />
                </FormControl>
              </Stack>

              <Stack direction='row' spacing={3}>
                {/* departure flight */}
                <FormControl>
                  <InputLabel htmlFor='depFlight'>Departure Flight</InputLabel>
                  <OutlinedInput
                    // style={{ width: 100 }}
                    id='depflight'
                    value={depFlight}
                    onChange={(e) => setDepFlight(e.target.value)}
                    label='depflight'
                  />
                </FormControl>

                {/* departure date */}
                <FormControl>
                  <InputLabel htmlFor='depDate'>
                    Departure Date: dd.mm.yyyy
                  </InputLabel>
                  <OutlinedInput
                    // style={{ width: 100 }}
                    id='depdate'
                    value={depDate}
                    onChange={(e) => setDepDate(e.target.value)}
                    label='depdate'
                  />
                </FormControl>
              </Stack>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button variant='contained' onClick={handleClose}>
              Close
            </Button>
            <Button variant='contained' onClick={handleSubmit}>
              Submit
            </Button>
          </DialogActions>
          {errorFlights && <Alert severity='error'>{errorFlights}</Alert>}

          {/* error handling for joinging event */}
          {loadingPlayer && (
            <Alert severity='success'>You have joined {cambo.name}</Alert>
          )}
          {errorPlayer && <Alert severity='error'>{errorPlayer}</Alert>}
          {successPlayer && (
            <Alert severity='success'>You have joined {cambo.name}</Alert>
          )}
        </Dialog>
      )}
    </>
  )
}

export default SignupDialog
