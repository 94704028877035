import React from "react"
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material"

const Itinerary = ({ cambo }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 100 }} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Club</TableCell>
            <TableCell>Course</TableCell>
            <TableCell>Bus Time</TableCell>
            <TableCell>Tee Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Day 1</TableCell>
            <TableCell>{cambo.course1?.clubName}</TableCell>
            <TableCell>{cambo.course1?.courseName}</TableCell>
            <TableCell>{cambo.day1Time}</TableCell>
            <TableCell>{cambo.day1Tee}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Day 2</TableCell>
            <TableCell>{cambo.course2?.clubName}</TableCell>
            <TableCell>{cambo.course2?.courseName}</TableCell>
            <TableCell>{cambo.day2Time}</TableCell>
            <TableCell>{cambo.day2Tee}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Day 3</TableCell>
            <TableCell>{cambo.course3?.clubName}</TableCell>
            <TableCell>{cambo.course3?.courseName}</TableCell>
            <TableCell>{cambo.day3Time}</TableCell>
            <TableCell>{cambo.day3Tee}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Day 4</TableCell>
            <TableCell>{cambo.course4?.clubName}</TableCell>
            <TableCell>{cambo.course4?.courseName}</TableCell>
            <TableCell>{cambo.day4Time}</TableCell>
            <TableCell>{cambo.day4Tee}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default Itinerary
