import React, { useEffect } from "react"
import { Link as RouterLink } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
  listCambos,
  createCambo,
  deleteCambo,
} from "../../actions/camboActions"
import { CAMBO_CREATE_RESET } from "../../constants/camboConstants"
import {
  Grid,
  LinearProgress,
  CircularProgress,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Stack,
} from "@mui/material"

const ArchivedAdminList = () => {
  // redux method
  const dispatch = useDispatch()

  // reacter router v6 methods
  const navigate = useNavigate()

  // cambo list  state from store
  const camboList = useSelector((state) => state.camboList)
  const { loading, error, cambos } = camboList // destructuring

  // cambo create state from store
  const camboCreate = useSelector((state) => state.camboCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    cambo: createdCambo,
  } = camboCreate // destructuring

  // user state from store
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin // destructuring

  // user delete state from store
  const camboDelete = useSelector((state) => state.camboDelete)
  const { success: successDelete } = camboDelete // destructuring

  // initialize action to get all cambos down to state
  useEffect(() => {
    dispatch({ type: CAMBO_CREATE_RESET })
    if (userInfo) {
      dispatch(listCambos())
    } else {
      navigate("/")
    }
    if (successCreate && userInfo.isAdmin) {
      navigate(`/admin/cambo/${createdCambo._id}/edit`)
    }
  }, [dispatch, navigate, userInfo, createdCambo, successCreate, successDelete])

  // create new cambo
  const createCamboHandler = (cambo) => {
    dispatch(createCambo())
  }

  // delete cambo
  const deleteHandler = (id) => {
    if (window.confirm("Are you sure you want to delete this event?")) {
      dispatch(deleteCambo(id))
    }
  }

  return (
    <Grid
      container
      spacing={{ xs: 2, md: 2 }}
      justifyContent='center'
      alignItems='center'
      direction='row'
    >
      {/* add event / go back buttons */}
      <Grid item xs={12}>
        <Stack direction='row' spacing={3}>
          <Button
            size='small'
            variant='contained'
            component={RouterLink}
            to='/'
          >
            Back
          </Button>
          {userInfo && userInfo.isAdmin && (
            <Button
              size='small'
              variant='contained'
              onClick={createCamboHandler}
            >
              Add Event
            </Button>
          )}
          {/* error / loading  */}
          {loadingCreate && <LinearProgress color='success' />}
          {errorCreate && <Alert severity='error'>{errorCreate}</Alert>}
        </Stack>
      </Grid>

      <Grid item xs={12}>
        {/* error / loading */}
        {loading ? (
          <LinearProgress severity='success' />
        ) : error ? (
          <Alert severity='error'>{error}</Alert>
        ) : (
          <>
            {/* event list table */}
            <TableContainer
              sx={{ display: { xs: "none", md: "block" } }}
              component={Paper}
            >
              <Table aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell>Event</TableCell>
                    <TableCell>Country</TableCell>
                    <TableCell>Dates</TableCell>
                    <TableCell>Active</TableCell>
                    <TableCell>Live</TableCell>
                    <TableCell>Delete</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {cambos
                    .filter((cambo) => cambo.isArchived === true)
                    .map((cambo) => (
                      <TableRow key={cambo._id}>
                        <TableCell
                          style={{ textDecoration: "none", color: "inherit" }}
                          component={RouterLink}
                          to={`/admin/cambo/${cambo._id}/edit`}
                        >
                          {cambo.name}{" "}
                        </TableCell>
                        <TableCell
                          style={{ textDecoration: "none", color: "inherit" }}
                          component={RouterLink}
                          to={`/admin/cambo/${cambo._id}/edit`}
                        >
                          {cambo.country}
                        </TableCell>
                        <TableCell
                          style={{ textDecoration: "none", color: "inherit" }}
                          component={RouterLink}
                          to={`/admin/cambo/${cambo._id}/edit`}
                        >
                          {cambo.dates}
                        </TableCell>
                        <TableCell>
                          {cambo.active && !cambo.live ? (
                            <Alert severity='success'>Open</Alert>
                          ) : (
                            <Alert severity='error'>Closed</Alert>
                          )}
                        </TableCell>
                        <TableCell>
                          {cambo.live ? (
                            <CircularProgress color='success' />
                          ) : null}
                        </TableCell>
                        <TableCell>
                          <Button
                            style={{ color: "#FF0000" }}
                            size='small'
                            onClick={() => deleteHandler(cambo._id)}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/* mobile screen event list table */}
            <TableContainer
              sx={{ display: { xs: "block", md: "none" } }}
              component={Paper}
            >
              <Table aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell>Country</TableCell>
                    <TableCell>Active</TableCell>
                    <TableCell>Live</TableCell>
                    <TableCell>Delete</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {cambos
                    .filter((cambo) => cambo.isArchived === true)
                    .map((cambo) => (
                      <TableRow key={cambo._id}>
                        <TableCell
                          style={{ textDecoration: "none", color: "inherit" }}
                          component={RouterLink}
                          to={`/admin/cambo/${cambo._id}/edit`}
                        >
                          {cambo.country}
                        </TableCell>

                        <TableCell>
                          {cambo.active && !cambo.live ? (
                            <Alert severity='success'>Open</Alert>
                          ) : (
                            <Alert severity='error'>Closed</Alert>
                          )}
                        </TableCell>
                        <TableCell>
                          {cambo.live ? (
                            <CircularProgress color='success' />
                          ) : null}
                        </TableCell>
                        <TableCell>
                          <Button
                            style={{ color: "#FF0000" }}
                            size='small'
                            onClick={() => deleteHandler(cambo._id)}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Grid>
    </Grid>
  )
}

export default ArchivedAdminList
