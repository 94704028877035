import {
  TIGER_CREATE_REQUEST,
  TIGER_CREATE_SUCCESS,
  TIGER_CREATE_FAIL,
  TIGER_CREATE_RESET,
  TIGER_LIST_REQUEST,
  TIGER_LIST_SUCCESS,
  TIGER_LIST_FAIL,
  TIGER_DETAILS_REQUEST,
  TIGER_DETAILS_SUCCESS,
  TIGER_DETAILS_FAIL,
  TIGER_DELETE_REQUEST,
  TIGER_DELETE_SUCCESS,
  TIGER_DELETE_FAIL,
  TIGER_UPDATE_REQUEST,
  TIGER_UPDATE_SUCCESS,
  TIGER_UPDATE_FAIL,
  TIGER_UPDATE_RESET,
  TIGER_SYNC_REQUEST,
  TIGER_SYNC_SUCCESS,
  TIGER_SYNC_FAIL,
} from "../constants/tigerConstants"

// reducer to list tiger events
export const tigerListReducer = (state = { tigers: [] }, action) => {
  switch (action.type) {
    case TIGER_LIST_REQUEST:
      return { loading: true, tigers: [] }
    case TIGER_LIST_SUCCESS:
      return { loading: false, tigers: action.payload }
    case TIGER_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

// reducer to display tiger event details
export const tigerDetailsReducer = (
  state = { tiger: { players: [] } },
  action
) => {
  switch (action.type) {
    case TIGER_DETAILS_REQUEST:
      return { loading: true, ...state }
    case TIGER_DETAILS_SUCCESS:
      return { loading: false, tiger: action.payload }
    case TIGER_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

// reducer to create tiger event
export const tigerCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case TIGER_CREATE_REQUEST:
      return { loading: true }
    case TIGER_CREATE_SUCCESS:
      return { loading: false, success: true, tiger: action.payload }
    case TIGER_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case TIGER_CREATE_RESET:
      return {}
    default:
      return state
  }
}

// reducer to update tiger event
export const tigerUpdateReducer = (state = { tiger: {} }, action) => {
  switch (action.type) {
    case TIGER_UPDATE_REQUEST:
      return { loading: true }
    case TIGER_UPDATE_SUCCESS:
      return { loading: false, success: true, tiger: action.payload }
    case TIGER_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case TIGER_UPDATE_RESET:
      return { tiger: {} }
    default:
      return state
  }
}

// reducer to delete a tiger event
export const tigerDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case TIGER_DELETE_REQUEST:
      return { loading: true }
    case TIGER_DELETE_SUCCESS:
      return { loading: false, success: true }
    case TIGER_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

// reducer to sync a tiger event scores with cambos
export const tigerSyncReducer = (state = {}, action) => {
  switch (action.type) {
    case TIGER_SYNC_REQUEST:
      return { loading: true }
    case TIGER_SYNC_SUCCESS:
      return { loading: false, success: true, tiger: action.payload }
    case TIGER_SYNC_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
