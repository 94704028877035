import React from "react"
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material"

const CourseData = ({ course }) => {
  return (
    <>
      {course && (
        <>
          <Grid
            container
            spacing={{ xs: 2, md: 2 }}
            justifyContent='center'
            alignItems='center'
            direction='column'
          >
            {/* club name */}
            <Grid item xs={12} sm={12} md={12}>
              <Typography variant='h5' sx={{ marginTop: 5 }}>
                {course.clubName}, {course.country}
              </Typography>
            </Grid>

            {/* course name */}
            <Grid item xs={12} sm={12} md={12}>
              <Typography variant='h7'>{course.courseName}</Typography>
            </Grid>

            {/* course par */}
            <Grid item xs={12} sm={12} md={12}>
              <Typography variant='h7'>Par: {course.par}</Typography>
            </Grid>

            {/* course ratings table */}
            <Grid item xs={12} sm={12} md={12}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 150 }} aria-label='simple table'>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={4} style={{ textAlign: "center" }}>
                        Course/Slope rating
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          backgroundColor: "#9D9D9D",
                          textAlign: "center",
                        }}
                      >
                        Black
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "lightblue",
                          textAlign: "center",
                        }}
                      >
                        Blue
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        White
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "#FF5C5C",
                          textAlign: "center",
                        }}
                      >
                        Red
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    <TableRow key={course._id}>
                      <TableCell
                        style={{
                          backgroundColor: "#9D9D9D",
                          textAlign: "center",
                        }}
                      >
                        {" "}
                        {course.ratingBlack}/{course.slopeBlack}{" "}
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "lightblue",
                          textAlign: "center",
                        }}
                      >
                        {course.ratingBlue}/{course.slopeBlue}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {course.ratingWhite}/{course.slopeWhite}
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "#FF5C5C",
                          textAlign: "center",
                        }}
                      >
                        {course.ratingRed}/{course.slopeRed}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {/* course website links */}
            <Grid item xs={12} sm={12} md={12}>
              <Typography>
                <a href={course.website}>{course.website}</a>
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}

export default CourseData
