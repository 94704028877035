import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link as RouterLink } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { getUserDetails, updateUserScore } from "../../actions/userActions"
import { listCourses } from "../../actions/courseActions"
import { USER_SCORING_ADD_RESET } from "../../constants/userConstants"
import {
  OutlinedInput,
  InputLabel,
  Button,
  FormControl,
  Typography,
  Grid,
  LinearProgress,
  Alert,
  TextField,
  MenuItem,
  Select,
  Stack,
} from "@mui/material"
import dayjs from "dayjs"
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import ScoreDisplay from "../../components/users/ScoreDisplay"
import Autocomplete from "@mui/material/Autocomplete"

const Scoring = () => {
  // react router dom v6 methods
  const navigate = useNavigate()

  // redux
  const dispatch = useDispatch()

  // user details state from store.js
  const userDetails = useSelector((state) => state.userDetails)
  const { loading, user, error } = userDetails

  // login state from store.js
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  // course list  state from store
  const courseList = useSelector((state) => state.courseList)
  const { courses } = courseList

  // scoring reduer state from store
  const addScore = useSelector((state) => state.addScore)
  const { error: scoreError, success: scoreSuccess } = addScore

  // component state
  const [message, setMessage] = useState(null)
  const [scoreMessage, setScoreMessage] = useState(null)
  const [score, setScore] = useState(0)
  const [course, setCourse] = useState(null)
  const [tee, setTee] = useState("")
  const [date, setDate] = React.useState(dayjs(Date.now()))
  const [dateError, setDateError] = useState(false)

  // dispatch
  useEffect(() => {
    if (scoreSuccess) {
      dispatch({ type: USER_SCORING_ADD_RESET })
      setCourse(null)
      setScore(0)
      setTee("")
      setDate(dayjs(Date.now()))
      setScoreMessage("Score saved")
      dispatch(getUserDetails("profile"))
      setTimeout(() => {
        setScoreMessage(null)
      }, 3000)
    }
    if (!userInfo) {
      navigate("/")
    } else {
      if (!user || !user.firstName) {
        dispatch(getUserDetails("profile"))
        dispatch(listCourses())
      }
    }
  }, [userInfo, user, navigate, dispatch, scoreSuccess])

  // handler for submitting sores
  const submitScore = (e) => {
    e.preventDefault()
    if (course === null || score === 0 || tee === "") {
      setMessage("One or more required fields are empty")
      setTimeout(() => {
        setMessage(null)
      }, 3000)
    } else {
      if (
        window.confirm(
          "Please ensure the slope and course ratings are entered in the courses section for the course & tee's you have selected, if not, please let us know and we will edit it before you submit the score \n \n To submit score click OK"
        )
      ) {
        dispatch(
          updateUserScore({
            id: user._id,
            course,
            score,
            tee,
            date,
          })
        )
      }
    }
  }

  // handle tee selector change
  const handleChangeTee = (e) => {
    setTee(e.target.value)
  }
  // handle date select change
  const handleDateChange = (newValue) => {
    setDate(newValue)
  }
  //handle date input error
  const handleBlur = () => {
    if (!date) {
      setDateError(true)
    }
  }
  // handle score input
  const handleScoreChange = (e) => {
    setScore(e.target.value)
  }

  return (
    <Grid
      container
      spacing={{ xs: 3, md: 4 }}
      justifyContent='flex-start'
      alignItems='center'
      direction='column'
    >
      {/* back button */}
      <Grid item xs={12} sm={12} md={12}>
        <Button size='small' variant='contained' component={RouterLink} to='/'>
          Back
        </Button>
      </Grid>

      {/* label */}
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant='h3'>Stroke Scores</Typography>
      </Grid>

      {/* handicap */}
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant='h6'>Handicap: {userInfo.handicap}</Typography>
      </Grid>

      {/* Error & Loading  */}
      <Grid item xs={12} sm={12} md={12}>
        {message && <Alert severity='error'>{message}</Alert>}
        {error && <Alert severity='error'>{error}</Alert>}
        {scoreError && <Alert severity='error'>{scoreError}</Alert>}
        {loading && <LinearProgress color='success' />}
        {scoreMessage && <Alert severity='success'>{scoreMessage}</Alert>}
      </Grid>

      {/* date selector */}
      <Grid item xs={12} sm={12} md={12}>
        <FormControl>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label='Date DD/MM/YYYY'
              inputFormat='DD/MM/YYYY'
              disableFuture
              value={date}
              onChange={handleDateChange}
              error={dateError}
              required
              onBlur={handleBlur}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </FormControl>
      </Grid>

      {/* list courses out in select in aplha oroder */}
      <Grid item xs={12} sm={12} md={12}>
        <FormControl>
          <Autocomplete
            disablePortal
            options={courses}
            getOptionLabel={(option) =>
              option.clubName + " " + option.courseName
            }
            id='course'
            value={course}
            onChange={(event, newValue) => {
              setCourse(newValue)
            }}
            sx={{ width: 250 }}
            renderInput={(params) => <TextField {...params} label='Course' />}
          />
        </FormControl>
      </Grid>

      {/* scoring entry */}
      <Grid item xs={12} sm={12} md={12} sx={{ marginTop: 2 }}>
        <Stack direction='row' spacing={4}>
          <FormControl>
            <InputLabel htmlFor='score'>Score</InputLabel>
            <OutlinedInput
              id='score'
              value={score}
              onChange={handleScoreChange}
              label='Score'
              type='number'
              style={{ width: 100 }}
            />
          </FormControl>

          <FormControl>
            <InputLabel id='tee'>Tee</InputLabel>
            <Select
              labelId='tee'
              id='tee'
              value={tee}
              label='Tee'
              onChange={handleChangeTee}
              style={{ width: 100 }}
            >
              <MenuItem value={"black"}>Black</MenuItem>
              <MenuItem value={"blue"}>Blue</MenuItem>
              <MenuItem value={"white"}>White</MenuItem>
              <MenuItem value={"red"}>Red</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </Grid>

      {/* submit score button */}
      <Grid item xs={12} sm={12} md={12}>
        <FormControl>
          <Button
            type='button'
            style={{ width: "100%" }}
            onClick={submitScore}
            variant='contained'
          >
            Submit Score
          </Button>
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={12} md={12}>
        {/* display the last 2o scores used to calculate handicap */}
        {loading ? (
          <LinearProgress severity='success' />
        ) : (
          <ScoreDisplay user={user} />
        )}
      </Grid>
    </Grid>
  )
}

export default Scoring
