const countries = [
  { label: "Australia", value: "AU" },
  { label: "United States", value: "US" },
  { label: "Scotland", value: "GB-SCT" },
  { label: "England", value: "GB-ENG" },
  { label: "Singapore", value: "SG" },
  { label: "New Zealand", value: "NZ" },
  { label: "Wales", value: "GB-WLS" },
  { label: "India", value: "IN" },
  { label: "Belgium", value: "BE" },
  { label: "Malaysia", value: "MY" },
  { label: "Liechtenstein", value: "LI" },
  { label: "Switzerland", value: "CH" },
  { label: "France", value: "FR" },
  { label: "Germany", value: "DE" },
]

export default countries
