import React, { useEffect } from "react"
import { Link as RouterLink } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { listCambos, createCambo } from "../../actions/camboActions"
import { CAMBO_CREATE_RESET } from "../../constants/camboConstants"
import {
  Grid,
  LinearProgress,
  CircularProgress,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Stack,
} from "@mui/material"

const CamboSchedule = () => {
  // redux method
  const dispatch = useDispatch()

  // reacter router v6 methods
  const navigate = useNavigate()

  // cambo list  state from store
  const camboList = useSelector((state) => state.camboList)
  const { loading, error, cambos } = camboList // destructuring

  // cambo create state from store
  const camboCreate = useSelector((state) => state.camboCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    cambo: createdCambo,
  } = camboCreate // destructuring

  // user state from store
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin // destructuring

  // initialize action to get all cambos down to state
  useEffect(() => {
    dispatch({ type: CAMBO_CREATE_RESET })
    if (userInfo) {
      dispatch(listCambos())
    } else {
      navigate("/")
    }
    if (successCreate && userInfo.isAdmin) {
      navigate(`/admin/cambo/${createdCambo._id}/edit`)
    }
  }, [dispatch, navigate, userInfo, createdCambo, successCreate])

  // create new cambo
  const createCamboHandler = (cambo) => {
    dispatch(createCambo())
  }
  return (
    <Grid
      container
      spacing={{ xs: 2, md: 2 }}
      justifyContent='center'
      alignItems='center'
      direction='row'
    >
      {/* go back / add event buttons */}
      <Grid item xs={12}>
        <Stack direction='row' spacing={3}>
          <Button
            size='small'
            variant='contained'
            component={RouterLink}
            to='/'
          >
            Back
          </Button>
          {userInfo && userInfo.isAdmin && (
            <Button
              size='small'
              variant='contained'
              onClick={createCamboHandler}
            >
              Add Event
            </Button>
          )}
          {loadingCreate && <LinearProgress color='success' />}
          {errorCreate && <Alert severity='error'>{errorCreate}</Alert>}
        </Stack>
      </Grid>

      {/* error / loading */}
      <Grid item xs={12}>
        {loading ? (
          <LinearProgress severity='success' />
        ) : error ? (
          <Alert severity='error'>{error}</Alert>
        ) : (
          <>
            {/* event list table */}
            <TableContainer
              sx={{ display: { xs: "none", md: "block" } }}
              component={Paper}
            >
              <Table aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell>Event</TableCell>
                    <TableCell>Country</TableCell>
                    <TableCell>Dates</TableCell>
                    <TableCell>Register</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Live</TableCell>
                  </TableRow>
                </TableHead>
                {/* maps and filters out any past events that have been archived  */}
                <TableBody>
                  {cambos
                    .filter((cambo) => cambo.isArchived === false)
                    .map((cambo) => (
                      <TableRow
                        key={cambo._id}
                        component={RouterLink}
                        to={`/cambo/${cambo._id}`}
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <TableCell>{cambo.name} </TableCell>

                        <TableCell>{cambo.country} </TableCell>
                        <TableCell>{cambo.dates}</TableCell>

                        <TableCell>
                          {" "}
                          {cambo.active && !cambo.live ? (
                            <Alert severity='success'>Open</Alert>
                          ) : (
                            <Alert severity='error'>Closed</Alert>
                          )}
                        </TableCell>
                        <TableCell>
                          {cambo.active && cambo.live ? (
                            <Alert severity='success'>Live</Alert>
                          ) : null}
                          {!cambo.active && !cambo.live ? (
                            <Alert severity='error'>Closed</Alert>
                          ) : null}
                          {cambo.active && !cambo.live ? (
                            <Alert severity='info'>Upcoming</Alert>
                          ) : null}
                        </TableCell>
                        <TableCell>
                          {" "}
                          {cambo.live ? (
                            <CircularProgress color='success' />
                          ) : null}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Mobile Screen event list table */}
            <TableContainer
              sx={{ display: { xs: "block", md: "none" } }}
              component={Paper}
            >
              <Table aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell>Country</TableCell>

                    <TableCell>Status</TableCell>
                    <TableCell>Live</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {cambos
                    .filter((cambo) => cambo.isArchived === false)
                    .map((cambo) => (
                      <TableRow
                        key={cambo._id}
                        component={RouterLink}
                        to={`/cambo/${cambo._id}`}
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <TableCell>{cambo.country} </TableCell>

                        <TableCell>
                          {" "}
                          {cambo.active && !cambo.live ? (
                            <Alert severity='info'>Open</Alert>
                          ) : null}
                          {cambo.active && cambo.live ? (
                            <Alert severity='success'>Live</Alert>
                          ) : null}
                          {!cambo.active && !cambo.live ? (
                            <Alert severity='error'>Closed</Alert>
                          ) : null}
                        </TableCell>

                        <TableCell>
                          {" "}
                          {cambo.live ? (
                            <CircularProgress color='success' />
                          ) : null}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Grid>
    </Grid>
  )
}

export default CamboSchedule
