import React, { useEffect } from "react"
import { Link as RouterLink } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { useParams, useNavigate } from "react-router-dom"
import { listCamboDetails } from "../../actions/camboActions"
import { DataGrid, GridToolbar } from "@mui/x-data-grid"
import { Grid, Box, Button, Alert, LinearProgress } from "@mui/material"

const PlayerGrid = () => {
  // react router dom
  const params = useParams()
  const navigate = useNavigate()

  // redux
  const dispatch = useDispatch()

  //cambo details state from store.js
  const camboDetails = useSelector((state) => state.camboDetails)
  const { cambo, loading, error } = camboDetails

  // user state from store
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin // destructuring

  // dispatch
  useEffect(() => {
    if (userInfo) {
      dispatch(listCamboDetails(params.id))
    } else {
      navigate("/login")
    }
  }, [dispatch, navigate, userInfo, params.id])

  // column logic for data grid and course filtering
  const columns = [
    {
      field: "firstName",
      headerName: "First Name",
      width: 200,
    },
    { field: "lastName", headerName: "last Name", width: 200 },
    { field: "arrFlight", headerName: "Arrival Flight/Ferry", width: 200 },
    {
      field: "arrDate",
      headerName: "Arrival Date",
      width: 200,
    },
    {
      field: "depFlight",
      headerName: "Departure Flight/Ferry",
      width: 200,
    },
    {
      field: "depDate",
      headerName: "Departure Date",
      width: 200,
    },
    {
      field: "fullName",
      headerName: "Full Name - Passport",
      width: 200,
    },
    {
      field: "passportNumber",
      headerName: "Passport Number",
      width: 200,
    },
    {
      field: "passportIssue",
      headerName: "Issue Date",
      width: 200,
    },
    {
      field: "passportExpiry",
      headerName: "Expiry Date",
      width: 200,
    },
    {
      field: "dob",
      headerName: "D.O.B",
      width: 200,
    },
  ]
  return (
    <Grid
      container
      spacing={{ xs: 2, md: 2 }}
      justifyContent='center'
      alignItems='center'
      direction='column'
    >
      <Grid item xs={12}>
        {/* go back button */}
        <Button
          size='small'
          variant='contained'
          component={RouterLink}
          to={`/cambo/${cambo._id}`}
        >
          Back
        </Button>
      </Grid>
      {loading && <LinearProgress color='success' />}
      {error && <Alert severity='error'>{error}</Alert>}
      <Grid item xs={12}>
        <Box style={{ height: 600, width: 960 }}>
          <DataGrid
            components={{ Toolbar: GridToolbar }}
            rows={cambo.players}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
            getRowId={(row) => row._id}
          />
        </Box>
      </Grid>
    </Grid>
  )
}

export default PlayerGrid
