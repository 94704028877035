import { createStore, combineReducers, applyMiddleware } from "redux"
import thunk from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"
import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateReducer,
  userListReducer,
  userDeleteReducer,
  userEditReducer,
  userScoringReducer,
  userCalculateHandicapReducer,
} from "./reducers/userReducers"
import {
  courseCreateReducer,
  courseDeleteReducer,
  courseDetailsReducer,
  courseListReducer,
  courseUpdateReducer,
} from "./reducers/courseReducers"
import {
  addPlayerReducer,
  camboCreateReducer,
  camboDetailsReducer,
  camboListReducer,
  camboUpdateReducer,
  camboDeleteReducer,
  camboUpdateScoringReducer,
  camboUpdateFlightReducer,
  camboSortPlayerReducer,
  deletePlayerReducer,
} from "./reducers/camboReducers"
import {
  tigerListReducer,
  tigerDetailsReducer,
  tigerCreateReducer,
  tigerUpdateReducer,
  tigerDeleteReducer,
  tigerSyncReducer,
} from "./reducers/tigerReducers"

// reducers in one variable
const reducer = combineReducers({
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdate: userUpdateReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userEdit: userEditReducer,
  courseList: courseListReducer,
  camboList: camboListReducer,
  camboDetails: camboDetailsReducer,
  addPlayer: addPlayerReducer,
  camboCreate: camboCreateReducer,
  camboDelete: camboDeleteReducer,
  editCambo: camboUpdateReducer,
  updateScoring: camboUpdateScoringReducer,
  updateFlights: camboUpdateFlightReducer,
  courseCreate: courseCreateReducer,
  editCourse: courseUpdateReducer,
  courseDetails: courseDetailsReducer,
  courseDelete: courseDeleteReducer,
  addScore: userScoringReducer,
  sortPlayers: camboSortPlayerReducer,
  deleteCamboPlayer: deletePlayerReducer,
  calculateHandicap: userCalculateHandicapReducer,
  tigerList: tigerListReducer,
  tigerDetails: tigerDetailsReducer,
  tigerCreate: tigerCreateReducer,
  tigerUpdate: tigerUpdateReducer,
  tigerDelete: tigerDeleteReducer,
  tigerSync: tigerSyncReducer,
})

// load userInfo from local storage
const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null

// creates intial state on loading
const initialState = {
  userLogin: { userInfo: userInfoFromStorage }, // user state
}

// thunk middleware
const middleware = [thunk]

// creates the store
const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store
