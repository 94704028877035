import React from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import ReactCountryFlag from "react-country-flag"

const TigerTable = ({ tiger }) => {
  return (
    <>
      {/* destop table */}
      <TableContainer
        component={Paper}
        sx={{ display: { xs: "none", md: "block" } }}
      >
        <Table sx={{ minWidth: 100 }}>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Games</TableCell>
              <TableCell>Average</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tiger.players
              ?.sort((a, b) => b.average - a.average)
              .map((player, i) => (
                <>
                  <TableRow key={i}>
                    <TableCell>
                      <ReactCountryFlag
                        countryCode={player.country}
                        svg
                        style={{
                          fontSize: "2em",
                          lineHeight: "2em",
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      {player.firstName} {player.lastName}
                    </TableCell>
                    <TableCell>{player.scores.length}</TableCell>
                    <TableCell>{player.average}</TableCell>
                  </TableRow>
                </>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* mobile table */}
      <TableContainer
        component={Paper}
        sx={{ display: { xs: "block", md: "none" } }}
      >
        <Table sx={{ minWidth: 80 }}>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Average</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tiger.players
              ?.sort((a, b) => b.average - a.average)
              .map((player, i) => (
                <>
                  <TableRow key={i}>
                    <TableCell>
                      <ReactCountryFlag
                        countryCode={player.country}
                        svg
                        style={{
                          fontSize: "2em",
                          lineHeight: "2em",
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      {player.firstName} {player.lastName}
                    </TableCell>
                    <TableCell>{player.average}</TableCell>
                  </TableRow>
                </>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default TigerTable
