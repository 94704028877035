import Header from "./components/headerBar/Header"
import Landing from "./screens/userScreens/Landing"
import Dashboard from "./screens/dashboard/Dashboard"
import Register from "./screens/userScreens/Register"
import CamboSchedule from "./screens/camboScreens/CamboSchedule"
import CourseList from "./screens/courseScreens/CourseList"
import CamboScreen from "./screens/camboScreens/CamboScreen"
import EditCambo from "./screens/camboScreens/EditCambo"
import EditCourse from "./screens/courseScreens/EditCourse"
import CamboAdminList from "./screens/camboScreens/CamboAdminList"
import ArchivedAdminList from "./screens/camboScreens/ArchivedAdminList"
import UserAdminList from "./screens/userScreens/UserAdminList"
import CourseAdmin from "./screens/courseScreens/CourseAdmin"
import UserProfile from "./screens/userScreens/UserProfile"
import UserEdit from "./screens/userScreens/UserEdit"
import Leaderboard from "./screens/camboScreens/Leaderboard"
import BasicLeaderboard from "./components/cambocup/leaderboards/BasicLeaderboard"
import Scoring from "./screens/userScreens/Scoring"
import PlayerGrid from "./screens/camboScreens/PlayerGrid"
import Footer from "./components/footerBar/Footer"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import { ThemeProvider, createTheme } from "@mui/material/styles"
import { CssBaseline } from "@mui/material"
import ArchivedEvents from "./components/cambocup/pastevents/ArchivedEvents"
import TigerAdmin from "./screens/tigerScreens/TigerAdmin"
import EditTiger from "./screens/tigerScreens/EditTiger"

const theme = createTheme({
  palette: {
    primary: {
      main: "#1d167e",
    },
    secondary: {
      main: "#e60505",
    },
    background: {
      default: "#FFFFFF",
    },
  },
})

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Header />
        <main>
          <Box>
            <Grid
              container
              spacing={0}
              direction='column'
              alignItems='center'
              justifyContent='center'
            >
              <Grid item xs={12}>
                <Routes>
                  <Route path='/' element={<Landing />} />
                  <Route path='/dashboard' element={<Dashboard />} />
                  <Route path='/register' element={<Register />} />
                  <Route path='/camboschedule' element={<CamboSchedule />} />
                  <Route path='/archivedevents' element={<ArchivedEvents />} />
                  <Route path='/courselist' element={<CourseList />} />
                  <Route
                    path='/admin/course/:id/edit'
                    element={<EditCourse />}
                  />
                  <Route path='/cambo/:id' element={<CamboScreen />} />
                  <Route path='/admin/cambo/:id/edit' element={<EditCambo />} />
                  <Route
                    path='/admin/camboadmin'
                    element={<CamboAdminList />}
                  />
                  <Route
                    path='/admin/camboadmin/archived'
                    element={<ArchivedAdminList />}
                  />
                  <Route path='/admin/useradmin' element={<UserAdminList />} />
                  <Route path='/admin/user/:id/edit' element={<UserEdit />} />
                  <Route path='/admin/courseadmin' element={<CourseAdmin />} />
                  <Route path='/userprofile' element={<UserProfile />} />
                  <Route path='/userprofile/scoring' element={<Scoring />} />
                  <Route
                    path='/cambo/:id/leaderboard'
                    element={<Leaderboard />}
                  />
                  <Route
                    path='/cambo/:id/basicleaderboard'
                    element={<BasicLeaderboard />}
                  />
                  <Route
                    path='/cambo/:id/playergrid'
                    element={<PlayerGrid />}
                  />
                  <Route path='/admin/tiger' element={<TigerAdmin />} />
                  <Route path='/admin/tiger/:id/edit' element={<EditTiger />} />
                </Routes>
              </Grid>
            </Grid>
          </Box>
        </main>
        <Footer />
      </Router>
    </ThemeProvider>
  )
}

export default App
