import React, { useEffect } from "react"
import { Link as RouterLink, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import {
  listTigers,
  createTiger,
  deleteTiger,
} from "../../actions/tigerActions"
import { Grid, LinearProgress, Alert, Button, Stack } from "@mui/material"
import { TIGER_CREATE_RESET } from "../../constants/tigerConstants"
import TigerList from "../../components/racetotiger/datadisplays/TigerList"

const TigerAdmin = () => {
  // redux method
  const dispatch = useDispatch()

  // react router v6 method
  const navigate = useNavigate()

  // race to tiger list from store
  const tigerList = useSelector((state) => state.tigerList)
  const { loading, error, tigers } = tigerList

  // tiger create state from store
  const tigerCreate = useSelector((state) => state.tigerCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    tiger: createdTiger,
  } = tigerCreate

  // user login state from store
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  // tiger delete state from store
  const tigerDelete = useSelector((state) => state.tigerDelete)
  const { success: successDelete, error: errorDelete } = tigerDelete

  // initializing
  useEffect(() => {
    dispatch({ type: TIGER_CREATE_RESET })
    if (userInfo) {
      dispatch(listTigers())
    } else {
      navigate("/")
    }
    if (successCreate && userInfo.isAdmin) {
      navigate(`/admin/tiger/${createdTiger._id}/edit`)
    }
  }, [dispatch, navigate, userInfo, createdTiger, successCreate, successDelete])

  // create new tiger handler
  const createTigerHandler = (tiger) => {
    dispatch(createTiger())
  }

  // delete tiger handler
  const deleteTigerHandler = (id) => {
    if (window.confirm("Are you sure you want to delete this event?")) {
      console.log(id)
      dispatch(deleteTiger(id))
    }
  }
  return (
    <Grid
      conatiner
      spacing={{ xs: 2, md: 2 }}
      justifyContent='center'
      alignItems='center'
      driection='row'
    >
      <Grid item sx={12}>
        <Stack direction='row' spacing={3}>
          <Button
            size='small'
            variant='contained'
            component={RouterLink}
            to='/'
          >
            Back
          </Button>
          {userInfo && userInfo.isAdmin && (
            <Button
              size='small'
              variant='contained'
              onClick={createTigerHandler}
            >
              Add Event
            </Button>
          )}
          {/* error / loading  */}
          {loadingCreate && <LinearProgress color='success' />}
          {errorCreate && <Alert severity='error'>{errorCreate}</Alert>}
          {errorDelete && <Alert severity='error'>{errorDelete}</Alert>}
        </Stack>
      </Grid>

      {loading ? (
        <LinearProgress severity='success' style={{ marginTop: 20 }} />
      ) : error ? (
        <Alert severity='error' style={{ marginTop: 20 }}>
          {error}
        </Alert>
      ) : (
        <>
          <Grid item xs={12}>
            <TigerList
              tigers={tigers}
              deleteTigerHandler={deleteTigerHandler}
            />
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default TigerAdmin
