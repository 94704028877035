import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { listTigerDetails, updateTiger } from "../../actions/tigerActions"
import { TIGER_UPDATE_RESET } from "../../constants/tigerConstants"
import TigerEditForm from "../../components/racetotiger/datadisplays/TigerEditForm"

const EditTiger = () => {
  // react router dom v6 methods
  const navigate = useNavigate()
  const params = useParams()
  const tigerId = params.id

  // redux
  const dispatch = useDispatch()

  // tiger details from store
  const tigerDetails = useSelector((state) => state.tigerDetails)
  const { tiger } = tigerDetails

  // edit tiger from store
  const tigerUpdate = useSelector((state) => state.tigerUpdate)
  const { loading, error, success } = tigerUpdate

  const [live, setLive] = useState(false)
  const [archived, setArchive] = useState(false)

  useEffect(() => {
    if (success) {
      dispatch({ type: TIGER_UPDATE_RESET })
      navigate("/")
    } else {
      if (tiger._id !== tigerId) {
        dispatch(listTigerDetails(tigerId))
      }
      setLive(tiger.live)
      setArchive(tiger.archived)
    }
  }, [dispatch, tiger, tigerId, navigate, success])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      updateTiger({
        _id: tigerId,
        live,
        archived,
      })
    )
  }
  return (
    <TigerEditForm
      tiger={tiger}
      submitHandler={submitHandler}
      live={live}
      setLive={setLive}
      archived={archived}
      setArchive={setArchive}
      error={error}
      loading={loading}
    />
  )
}

export default EditTiger
