export const generateHandicapOptions = () => {
  const options = []
  for (let i = 0; i <= 36; i++) {
    options.push({ label: i.toFixed(1), value: i.toFixed(1) })
    for (let j = 1; j <= 9; j++) {
      const label = `${i}.${j}`
      const value = parseFloat(label).toFixed(1)
      options.push({ label, value })
    }
  }
  return options
}
