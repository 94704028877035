import React, { useEffect } from "react"
import { listTigers } from "../../../actions/tigerActions"
import { useDispatch, useSelector } from "react-redux"
import TigerTable from "./TigerTable"
import { LinearProgress, Grid, Alert, Typography } from "@mui/material"

const DashLeaderboard = () => {
  // format date data
  const formatDate = (date) => {
    const d = new Date(date)
    const yyyy = d.getFullYear()
    return `${yyyy}`
  }

  // react redux
  const dispatch = useDispatch()

  // race to tiger list from store
  const tigerList = useSelector((state) => state.tigerList)
  const { loading, error, tigers } = tigerList

  // user login state from store
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  // initializing
  useEffect(() => {
    dispatch(listTigers())
  }, [dispatch, userInfo])

  const tiger = tigers?.find((tig) => tig.live === true)
  return (
    <Grid
      conatiner
      spacing={{ xs: 2, md: 2 }}
      justifyContent='center'
      alignItems='center'
      driection='row'
    >
      <Grid item xs={12}>
        {loading ? (
          <LinearProgress color='success' />
        ) : error ? (
          <Alert severity='error'>{error}</Alert>
        ) : (
          <>
            {tiger ? (
              <>
                <Typography
                  variant='h3'
                  sx={{ display: { xs: "none", md: "block" } }}
                >
                  Race To Tiger {formatDate(tiger.year)}
                </Typography>
                <Typography
                  variant='h3'
                  sx={{ display: { xs: "block", md: "none" } }}
                >
                  Tiger {formatDate(tiger.year)}
                </Typography>
                <TigerTable tiger={tiger} />
              </>
            ) : null}
          </>
        )}
      </Grid>
    </Grid>
  )
}

export default DashLeaderboard
