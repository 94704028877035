import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { getUserDetails, updateUserDetails } from "../../actions/userActions"
import { listCourses } from "../../actions/courseActions"
import { USER_UPDATE_RESET } from "../../constants/userConstants"
import {
  OutlinedInput,
  InputLabel,
  Button,
  FormControl,
  Typography,
  Grid,
  LinearProgress,
  Alert,
  Box,
} from "@mui/material"

const UserProfile = () => {
  // react router dom v6 methods
  const navigate = useNavigate()

  // redux
  const dispatch = useDispatch()

  // user details state from store.js
  const userDetails = useSelector((state) => state.userDetails)
  const { loading, user, error } = userDetails

  // login state from store.js
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  // user update success state from store.js
  const userUpdate = useSelector((state) => state.userUpdate)
  const { success } = userUpdate

  // component state
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [password1, setPassword1] = useState("")
  const [message, setMessage] = useState(null)

  // dispatch
  useEffect(() => {
    if (!userInfo) {
      navigate("/")
    } else {
      if (!user || !user.firstName) {
        dispatch(getUserDetails("profile"))
      } else {
        setFirstName(user.firstName)
        setLastName(user.lastName)
        setEmail(user.email)
        dispatch(listCourses())
      }
      if (success) {
        dispatch({ type: USER_UPDATE_RESET })
        navigate("/dashboard")
        window.location.reload()
      }
    }
  }, [userInfo, user, navigate, dispatch, success])

  // submit edit handler
  const submitHandler = (e) => {
    e.preventDefault()
    if (password !== password1) {
      setMessage("Passwords do not match")
    } else {
      dispatch(
        updateUserDetails({
          id: user._id,
          firstName,
          lastName,
          email,
          password,
        })
      )
    }
  }

  return (
    <Box component='form' noValidate autoComplete='off'>
      <Grid
        container
        spacing={{ xs: 3, md: 4 }}
        justifyContent='flex-start'
        alignItems='center'
        direction='column'
      >
        {/* header label */}
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant='h3'>{user.firstName}'s Profile</Typography>
        </Grid>

        {/* Error & Loading  */}
        <Grid item xs={12} sm={12} md={12}>
          {message && <Alert severity='error'>{message}</Alert>}
          {error && <Alert severity='error'>{error}</Alert>}
          {success && <Alert severity='success'>Updated Successfully</Alert>}
          {loading && <LinearProgress color='success' />}
        </Grid>

        {/* first name input */}
        <Grid item xs={12} sm={12} md={12}>
          <FormControl>
            <InputLabel htmlFor='firstName'>First Name</InputLabel>
            <OutlinedInput
              id='firstName'
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              label='First Name'
            />
          </FormControl>
        </Grid>

        {/* last name input */}
        <Grid item xs={12} sm={12} md={12}>
          <FormControl>
            <InputLabel htmlFor='lastName'>Last Name</InputLabel>
            <OutlinedInput
              id='lastName'
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              label='Last Name'
            />
          </FormControl>
        </Grid>

        {/* email address input */}
        <Grid item xs={12} sm={12} md={12}>
          <FormControl>
            <InputLabel htmlFor='email'>Email</InputLabel>
            <OutlinedInput
              type='email'
              id='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              label='Email'
            />
          </FormControl>
        </Grid>

        {/* password input */}
        <Grid item xs={12} sm={12} md={12}>
          <FormControl>
            <InputLabel htmlFor='password'>Password</InputLabel>
            <OutlinedInput
              id='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              label='Password'
              type='password'
            />
          </FormControl>
        </Grid>

        {/* confirm password input */}
        <Grid item xs={12} sm={12} md={12}>
          <FormControl>
            <InputLabel htmlFor='password'>Confirm Password</InputLabel>
            <OutlinedInput
              id='password1'
              value={password1}
              onChange={(e) => setPassword1(e.target.value)}
              label='Confirm Password'
              type='password'
            />
          </FormControl>
        </Grid>

        {/* submit button */}
        <Grid item xs={12} sm={12} md={12}>
          <FormControl>
            <Button
              type='submit'
              style={{ width: "100%" }}
              onClick={submitHandler}
              variant='contained'
            >
              Update Profile
            </Button>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  )
}

export default UserProfile
