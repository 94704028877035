import React, { useEffect } from "react"
import { Link as RouterLink } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { listCambos } from "../../../actions/camboActions"
import { Winner } from "./Winner"
import WinnerFlag from "./WinnerFlag"
import {
  Grid,
  LinearProgress,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material"

const ArchivedEvents = () => {
  // redux method
  const dispatch = useDispatch()

  // reacter router v6 methods
  const navigate = useNavigate()

  // cambo list  state from store
  const camboList = useSelector((state) => state.camboList)
  const { loading, error, cambos } = camboList // destructuring

  // user state from store
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin // destructuring

  // initialize action to get all cambos down to state
  useEffect(() => {
    if (userInfo) {
      dispatch(listCambos())
    } else {
      navigate("/")
    }
  }, [dispatch, navigate, userInfo])

  return (
    <Grid
      container
      spacing={{ xs: 2, md: 2 }}
      justifyContent='center'
      alignItems='center'
      direction='row'
    >
      {/* go back / add event buttons */}
      <Grid item xs={12}>
        <Button size='small' variant='contained' component={RouterLink} to='/'>
          Back
        </Button>
      </Grid>

      {/* error / loading */}
      <Grid item xs={12}>
        {loading ? (
          <LinearProgress severity='success' />
        ) : error ? (
          <Alert severity='error'>{error}</Alert>
        ) : (
          <>
            {/* event list table */}
            <TableContainer
              sx={{ display: { xs: "none", md: "block" } }}
              component={Paper}
            >
              <Table aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell>Event</TableCell>
                    <TableCell>Country</TableCell>
                    <TableCell>Dates</TableCell>
                    <TableCell>Winner</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                {/* maps and filters out any past events that have been archived  */}
                <TableBody>
                  {cambos
                    .filter((cambo) => cambo.isArchived === true)
                    .map((cambo) => (
                      <TableRow
                        key={cambo._id}
                        component={RouterLink}
                        to={`/cambo/${cambo._id}/basicleaderboard`}
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <TableCell>{cambo.name} </TableCell>
                        <TableCell>{cambo.country} </TableCell>
                        <TableCell>{cambo.dates}</TableCell>
                        <Winner cambo={cambo} />
                        <WinnerFlag cambo={cambo} />
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/* Mobile Screen event list table */}
            <TableContainer
              sx={{ display: { xs: "block", md: "none" } }}
              component={Paper}
            >
              <Table aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell>Country</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Winner</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {cambos
                    .filter((cambo) => cambo.isArchived === true)
                    .map((cambo) => (
                      <TableRow
                        key={cambo._id}
                        component={RouterLink}
                        to={`/cambo/${cambo._id}/basicleaderboard`}
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <TableCell>{cambo.country} </TableCell>
                        <TableCell>{cambo.dates}</TableCell>
                        <Winner cambo={cambo} />
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Grid>
    </Grid>
  )
}

export default ArchivedEvents
