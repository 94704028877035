import React from "react"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import TeeIcon from "./TeeIcon"
import { Typography } from "@mui/material"

const ScoreDisplay = ({ user }) => {
  // format date data
  const formatDate = (date) => {
    const d = new Date(date)
    const dd = String(d.getDate()).padStart(2, "0")
    const mm = String(d.getMonth() + 1).padStart(2, "0")
    const yyyy = d.getFullYear()
    return `${dd}/${mm}/${yyyy}`
  }

  // used to make date format smaller for mobile layout
  const formatSmallDate = (date) => {
    const d = new Date(date)
    const dd = String(d.getDate()).padStart(2, "0")
    const mm = String(d.getMonth() + 1).padStart(2, "0")
    return `${dd}/${mm}`
  }

  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel3a-content'
          id='panel3a-header'
        >
          <Typography>Last 20 Scores</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* desktop layout */}
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 100, display: { xs: "none", md: "block" } }}
              aria-label='simple table'
            >
              <TableHead>
                <TableRow style={{ backgroundColor: "#F5F5F5" }}>
                  <TableCell></TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Score</TableCell>
                  <TableCell>Club</TableCell>
                  <TableCell>Course</TableCell>
                  <TableCell>Tee</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {user.scores?.map((score, i) => (
                  <React.Fragment key={i}>
                    <TableRow>
                      <TableCell>{i + 1}</TableCell>
                      <TableCell>{formatDate(score.date)}</TableCell>
                      <TableCell>{score.score}</TableCell>
                      <TableCell>{score.course.clubName}</TableCell>
                      <TableCell>{score.course.courseName}</TableCell>
                      <TableCell>
                        <TeeIcon score={score} />
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* mobile layout  */}
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 50, display: { xs: "block", md: "none" } }}
              aria-label='simple table'
            >
              <TableHead>
                <TableRow style={{ backgroundColor: "#F5F5F5" }}>
                  <TableCell>Date</TableCell>
                  <TableCell>Score</TableCell>
                  <TableCell>Club</TableCell>
                  <TableCell>Course</TableCell>
                  <TableCell>Tee</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {user.scores?.map((score, i) => (
                  <React.Fragment key={i}>
                    <TableRow>
                      <TableCell>{formatSmallDate(score.date)}</TableCell>
                      <TableCell>{score.score}</TableCell>
                      <TableCell>{score.course.clubName}</TableCell>
                      <TableCell>{score.course.courseName}</TableCell>
                      <TableCell>
                        <TeeIcon score={score} />
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
      {/* display all scores in user database */}
    </>
  )
}

export default ScoreDisplay
