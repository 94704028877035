import React, { useEffect } from "react"
import { Link as RouterLink } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { listUsers, deleteUser } from "../../actions/userActions"
import {
  Button,
  Typography,
  Grid,
  LinearProgress,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material"

const UserAdminList = () => {
  // redux method
  const dispatch = useDispatch()

  // reacter router v6 methods
  const navigate = useNavigate()

  // user list state from store
  const userList = useSelector((state) => state.userList)
  const { loading, error, users } = userList

  // user state from store
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  // user delete state from store
  const userDelete = useSelector((state) => state.userDelete)
  const { success: successDelete } = userDelete // destructuring

  // dispatch
  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listUsers())
    } else {
      navigate("/login")
    }
  }, [dispatch, navigate, userInfo, successDelete])

  // delete user
  const deleteHandler = (id) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      dispatch(deleteUser(id))
    }
  }

  return (
    <Grid
      container
      spacing={{ xs: 3, md: 4 }}
      justifyContent='flex-start'
      alignItems='center'
      direction='column'
    >
      {/* error or loading */}
      <Typography variant='h3'>Members</Typography>
      {loading ? (
        <LinearProgress severity='success' />
      ) : error ? (
        <Alert severity='error'>{error}</Alert>
      ) : (
        <>
          {/* user list table  */}
          <TableContainer
            component={Paper}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <Table sx={{ minWidth: 650 }} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell>Fist Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Admin</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {/* sorts users into alpha order by first name */}
                {users
                  .sort((a, b) => a.firstName.localeCompare(b.firstName))
                  .map((user) => (
                    <TableRow key={user._id}>
                      <TableCell>{user.firstName}</TableCell>
                      <TableCell>{user.lastName}</TableCell>
                      <TableCell>
                        <a href={`mailto:${user.email}`}>{user.email}</a>
                      </TableCell>
                      <TableCell>
                        {user.isAdmin ? (
                          <Alert severity='success'>Admin</Alert>
                        ) : (
                          <Alert severity='info'>Golfer</Alert>
                        )}
                      </TableCell>
                      <TableCell>
                        <Button
                          size='small'
                          component={RouterLink}
                          to={`/admin/user/${user._id}/edit`}
                          disabled={userInfo._id === user._id}
                        >
                          Edit
                        </Button>

                        <Button
                          style={{ color: "#FF0000" }}
                          size='small'
                          onClick={() => deleteHandler(user._id)}
                          disabled={userInfo._id === user._id}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/*  mobile view (less columns)*/}
          <TableContainer
            component={Paper}
            sx={{ display: { xs: "block", md: "none" } }}
          >
            <Table aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {users.map((user) => (
                  <TableRow key={user._id}>
                    <TableCell>
                      <a href={`mailto:${user.email}`}>{user.email}</a>
                    </TableCell>

                    <TableCell>
                      <Button
                        size='small'
                        component={RouterLink}
                        to={`/admin/user/${user._id}/edit`}
                        disabled={userInfo._id === user._id}
                      >
                        Edit
                      </Button>

                      <Button
                        style={{ color: "#FF0000" }}
                        size='small'
                        onClick={() => deleteHandler(user._id)}
                        disabled={userInfo._id === user._id}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Grid>
  )
}

export default UserAdminList
