import React from "react"
import { Grid } from "@mui/material"
// import { useSelector } from "react-redux"
// import { useNavigate } from "react-router-dom"
import UpcomingEvents from "../../components/dashboard/cards/UpcomingEvents"
import PastEvents from "../../components/dashboard/cards/PastEvents"
import GolfCourses from "../../components/dashboard/cards/GolfCourses"
import DashLeaderboard from "../../components/racetotiger/leaderboard/DashLeaderboard"

const Dashboard = () => {
  // react router dom v6
  // const navigate = useNavigate()
  // user login state from store
  // const userLogin = useSelector((state) => state.userLogin)
  // const { userInfo } = userLogin

  // // initializing
  // useEffect(() => {
  //   if (!userInfo) {
  //     navigate("/")
  //   }
  // }, [navigate, userInfo])

  return (
    <>
      <Grid
        container
        spacing={{ xs: 2, md: 2 }}
        justifyContent='center'
        alignItems='center'
        direction='row'
      >
        {/* upcoming cambo cup card link */}
        <Grid item>
          <UpcomingEvents />
        </Grid>

        {/* past cambo cup card link */}
        <Grid item>
          <PastEvents />
        </Grid>

        {/* course list card link */}
        <Grid item>
          <GolfCourses />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={{ xs: 2, md: 2 }}
        style={{ marginTop: 30 }}
        justifyContent='center'
        alignItems='center'
        direction='row'
      >
        <DashLeaderboard />
      </Grid>
    </>
  )
}

export default Dashboard
