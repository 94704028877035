import axios from "axios"
import {
  TIGER_CREATE_REQUEST,
  TIGER_CREATE_SUCCESS,
  TIGER_CREATE_FAIL,
  TIGER_LIST_REQUEST,
  TIGER_LIST_SUCCESS,
  TIGER_LIST_FAIL,
  TIGER_DETAILS_REQUEST,
  TIGER_DETAILS_SUCCESS,
  TIGER_DETAILS_FAIL,
  TIGER_DELETE_REQUEST,
  TIGER_DELETE_SUCCESS,
  TIGER_DELETE_FAIL,
  TIGER_UPDATE_REQUEST,
  TIGER_UPDATE_SUCCESS,
  TIGER_UPDATE_FAIL,
  TIGER_SYNC_REQUEST,
  TIGER_SYNC_SUCCESS,
  TIGER_SYNC_FAIL,
} from "../constants/tigerConstants"

// list tigers action
export const listTigers = () => async (dispatch, getState) => {
  try {
    dispatch({ type: TIGER_LIST_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/racetotiger`, config)
    dispatch({ type: TIGER_LIST_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: TIGER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// list tiger details action
export const listTigerDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: TIGER_DETAILS_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/racetotiger/${id}`, config)
    dispatch({ type: TIGER_DETAILS_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: TIGER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// create a race to tiger event action (admin only)
export const createTiger = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: TIGER_CREATE_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`/api/racetotiger`, {}, config)
    dispatch({ type: TIGER_CREATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: TIGER_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// update race to tiger event action (admin only)
export const updateTiger = (tiger) => async (dispatch, getState) => {
  try {
    dispatch({ type: TIGER_UPDATE_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `/api/racetotiger/${tiger._id}`,
      tiger,
      config
    )

    dispatch({ type: TIGER_UPDATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: TIGER_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// delete a tiger event action (admin only)
export const deleteTiger = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: TIGER_DELETE_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`/api/racetotiger/${id}`, config)

    dispatch({ type: TIGER_DELETE_SUCCESS })
  } catch (error) {
    dispatch({
      type: TIGER_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.messahge
          : error.message,
    })
  }
}

// delete a tiger event action (admin only)
export const syncTiger = () => async (dispatch, getState) => {
  try {
    dispatch({ type: TIGER_SYNC_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`/api/racetotiger/updatescores`, config)

    dispatch({ type: TIGER_SYNC_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: TIGER_SYNC_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.messahge
          : error.message,
    })
  }
}
