import React from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { Typography } from "@mui/material"
import ReactCountryFlag from "react-country-flag"

const MobileBasicLeaberboard = ({ cambo }) => {
  return (
    <div>
      {/* leaderboard table */}
      <TableContainer
        component={Paper}
        sx={{ display: { xs: "block", md: "none" } }}
      >
        <Table sx={{ minWidth: 350 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>
                <Typography variant='h6'>Total</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* sorts player leader from highest total */}
            {cambo.players
              .sort((a, b) => b.total - a.total)
              .map((player, i) => (
                <React.Fragment key={player._id}>
                  <TableRow>
                    <TableCell>
                      <ReactCountryFlag
                        countryCode={player.country}
                        svg
                        style={{
                          fontSize: "2em",
                          lineHeight: "2em",
                        }}
                      />
                    </TableCell>
                    <TableCell>{player.firstName} </TableCell>
                    <TableCell>{player.lastName}</TableCell>
                    <TableCell
                      align='center'
                      sx={{
                        padding: "0px 0px",
                        backgroundColor: "lightgray",
                        color: "red",
                      }}
                    >
                      {player.total}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default MobileBasicLeaberboard
