import React from "react"
import {
  Button,
  Alert,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  OutlinedInput,
  AlertTitle,
} from "@mui/material"

const EditTravelDialog = ({
  cambo,
  openEdit,
  setId,
  player,
  handleEdit,
  _id,
  editArrFlight,
  editArrDate,
  editDepFlight,
  editDepDate,
  editFullName,
  editPassportNumber,
  editPassportIssue,
  editPassportExpiry,
  editDOB,
  setEditArrFlight,
  setEditArrDate,
  setEditDepFlight,
  setEditDepDate,
  setEditFullName,
  setEditPassportNumber,
  setEditPassportExpiry,
  setEditPassportIssue,
  setEditDOB,
  userInfo,
  flightOpen,
  closeEdit,
  errorFlights,
}) => {
  return (
    <>
      {cambo.batam ? (
        // *! Batam arrival date edit dialog
        <form noValidate autoComplete='off' id='editForm' onSubmit={handleEdit}>
          <Dialog
            open={flightOpen[player._id]}
            onClose={() => closeEdit(player._id)}
            disablePortal
          >
            <DialogTitle>
              Edit {userInfo.firstName} {userInfo.lastName}'s Details
            </DialogTitle>
            <DialogContent>
              <DialogContentText style={{ marginBottom: 20 }}>
                Please enter arrival and departure information
              </DialogContentText>

              <Stack direction='column' spacing={3}>
                <FormControl>
                  <InputLabel htmlFor='_id'>ID</InputLabel>
                  <OutlinedInput
                    style={{ width: 250 }}
                    id='_id'
                    value={_id}
                    onChange={(e) => setId(e.target.value)}
                    label='Id'
                  />
                </FormControl>

                {/* full Name */}
                <FormControl>
                  <InputLabel htmlFor='editfullname'>
                    Full Name - Passport
                  </InputLabel>
                  <OutlinedInput
                    style={{ width: 350 }}
                    id='editfullname'
                    value={editFullName}
                    onChange={(e) => setEditFullName(e.target.value)}
                    label='editfullname'
                  />
                </FormControl>

                {/* passport number */}
                <FormControl>
                  <InputLabel htmlFor='editpassportnumber'>
                    Passport Number
                  </InputLabel>
                  <OutlinedInput
                    style={{ width: 350 }}
                    id='editpassportnumber'
                    value={editPassportNumber}
                    onChange={(e) => setEditPassportNumber(e.target.value)}
                    label='editpassportnumber'
                  />
                </FormControl>

                {/* passport issue date */}
                <FormControl>
                  <InputLabel htmlFor='editpassportissue'>
                    Issue Date
                  </InputLabel>
                  <OutlinedInput
                    id='editpassportissue'
                    value={editPassportIssue}
                    onChange={(e) => setEditPassportIssue(e.target.value)}
                    label='issuedate'
                  />
                </FormControl>

                {/* passport expiry date */}
                <FormControl>
                  <InputLabel htmlFor='editpassportexpiry'>
                    Expiry Date
                  </InputLabel>
                  <OutlinedInput
                    id='editpassportexpiry'
                    value={editPassportExpiry}
                    onChange={(e) => setEditPassportExpiry(e.target.value)}
                    label='editexpirydate'
                  />
                </FormControl>

                {/* passport date of birth*/}
                <FormControl>
                  <InputLabel htmlFor='editdob'>D.O.B</InputLabel>
                  <OutlinedInput
                    id='editdob'
                    value={editDOB}
                    onChange={(e) => setEditDOB(e.target.value)}
                    label='D.O.B'
                  />
                </FormControl>

                {/* arrival date */}
                <FormControl>
                  <InputLabel htmlFor='editArrDate'>
                    Arrival Date: dd.mm.yyyy
                  </InputLabel>
                  <OutlinedInput
                    style={{ width: 250 }}
                    id='editarrdate'
                    value={editArrDate}
                    onChange={(e) => setEditArrDate(e.target.value)}
                    label='arrdate'
                  />
                </FormControl>

                {/* arrival ferry */}
                <FormControl>
                  <InputLabel htmlFor='editArrFlight'>Arrival Ferry</InputLabel>
                  <OutlinedInput
                    // style={{ width: 100 }}
                    id='editarrflight'
                    value={editArrFlight}
                    onChange={(e) => setEditArrFlight(e.target.value)}
                    label='arrflight'
                  />
                </FormControl>

                {/* departure date */}
                <FormControl>
                  <InputLabel htmlFor='editDepDate'>
                    Departure Date: dd.mm.yyyy
                  </InputLabel>
                  <OutlinedInput
                    style={{ width: 250 }}
                    id='editdepdate'
                    value={editDepDate}
                    onChange={(e) => setEditDepDate(e.target.value)}
                    label='depdate'
                  />
                </FormControl>

                {/* departure ferry */}
                <FormControl>
                  <InputLabel htmlFor='editDepFlight'>
                    Departure Ferry
                  </InputLabel>
                  <OutlinedInput
                    // style={{ width: 100 }}
                    id='editdepflight'
                    value={editDepFlight}
                    onChange={(e) => setEditDepFlight(e.target.value)}
                    label='depflight'
                  />
                </FormControl>
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button variant='contained' onClick={() => closeEdit(player._id)}>
                Close
              </Button>
              <Button type='submit' variant='contained' form='editForm'>
                Submit
              </Button>
            </DialogActions>
            {errorFlights && (
              <Alert severity='error'>
                <AlertTitle>Error</AlertTitle>
                {errorFlights}
              </Alert>
            )}
          </Dialog>
        </form>
      ) : (
        // *! Non-Batam event arrival info edit dialog
        <form noValidate autoComplete='off' id='editForm' onSubmit={handleEdit}>
          <Dialog
            open={flightOpen[player._id]}
            onClose={() => closeEdit(player._id)}
            disablePortal
          >
            <DialogTitle>
              Edit {userInfo.firstName} {userInfo.lastName}'s Flights
            </DialogTitle>
            <DialogContent>
              <DialogContentText style={{ marginBottom: 20 }}>
                Please enter arrival and departure information
              </DialogContentText>

              <Stack direction='column' spacing={3}>
                <FormControl>
                  <InputLabel htmlFor='_id'>ID</InputLabel>
                  <OutlinedInput
                    style={{ width: 250 }}
                    id='_id'
                    value={_id}
                    onChange={(e) => setId(e.target.value)}
                    label='Id'
                  />
                </FormControl>
                <Stack direction='row' spacing={3}>
                  {/* arrival flight */}
                  <FormControl>
                    <InputLabel htmlFor='editArrFlight'>
                      Arrival Flight
                    </InputLabel>
                    <OutlinedInput
                      // style={{ width: 100 }}
                      id='editarrflight'
                      value={editArrFlight}
                      onChange={(e) => setEditArrFlight(e.target.value)}
                      label='arrflight'
                    />
                  </FormControl>

                  {/* arrival date */}
                  <FormControl>
                    <InputLabel htmlFor='editArrDate'>
                      Arrival Date: dd.mm.yyyy
                    </InputLabel>
                    <OutlinedInput
                      // style={{ width: 100 }}
                      id='editarrdate'
                      value={editArrDate}
                      onChange={(e) => setEditArrDate(e.target.value)}
                      label='arrdate'
                    />
                  </FormControl>
                </Stack>

                <Stack direction='row' spacing={3}>
                  {/* departure flight */}
                  <FormControl>
                    <InputLabel htmlFor='editDepFlight'>
                      Departure Flight
                    </InputLabel>
                    <OutlinedInput
                      // style={{ width: 100 }}
                      id='editdepflight'
                      value={editDepFlight}
                      onChange={(e) => setEditDepFlight(e.target.value)}
                      label='depflight'
                    />
                  </FormControl>

                  {/* departure date */}
                  <FormControl>
                    <InputLabel htmlFor='editDepDate'>
                      Departure Date: dd.mm.yyyy
                    </InputLabel>
                    <OutlinedInput
                      // style={{ width: 100 }}
                      id='editdepdate'
                      value={editDepDate}
                      onChange={(e) => setEditDepDate(e.target.value)}
                      label='depdate'
                    />
                  </FormControl>
                </Stack>
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button variant='contained' onClick={() => closeEdit(player._id)}>
                Close
              </Button>
              <Button type='submit' variant='contained' form='editForm'>
                Submit
              </Button>
            </DialogActions>
            {errorFlights && (
              <Alert severity='error'>
                <AlertTitle>Error</AlertTitle>
                {errorFlights}
              </Alert>
            )}
          </Dialog>
        </form>
      )}
    </>
  )
}

export default EditTravelDialog
