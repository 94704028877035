import React, { useEffect, useState } from "react"
import { Link as RouterLink } from "react-router-dom"
import { useParams, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import {
  listCamboDetails,
  addNewPlayer,
  updateCamboFlights,
  deletePlayer,
} from "../../actions/camboActions"
import {
  CAMBO_ADDPLAYER_RESET,
  CAMBO_PLAYER_DELETE_RESET,
} from "../../constants/camboConstants"
import { CAMBO_FLIGHTS_UPDATE_RESET } from "../../constants/camboConstants"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import SignupDialog from "../../components/cambocup/signupScreen/SignupDialog"
import EditTravelDialog from "../../components/cambocup/signupScreen/EditTravelDialog"
import DailyHandicaps from "../../components/cambocup/camboScreen/DailyHandicaps"
import Itinerary from "../../components/cambocup/camboScreen/Itinerary"
import HeaderText from "../../components/cambocup/camboScreen/HeaderText"
import emailjs from "@emailjs/browser"
import {
  Box,
  Typography,
  Button,
  LinearProgress,
  Alert,
  AlertTitle,
  ListItem,
  Stack,
} from "@mui/material"

const CamboScreen = () => {
  // component state
  const [open, setOpen] = useState(false)
  const [arrFlight, setArrFlight] = useState("")
  const [arrDate, setArrDate] = useState("")
  const [depFlight, setDepFlight] = useState("")
  const [depDate, setDepDate] = useState("")
  const [flightOpen, setFlightOpen] = React.useState({})
  const [_id, setId] = useState("")
  const [editArrFlight, setEditArrFlight] = useState("")
  const [editArrDate, setEditArrDate] = useState("")
  const [editDepFlight, setEditDepFlight] = useState("")
  const [editDepDate, setEditDepDate] = useState("")
  const [fullName, setFullName] = useState("")
  const [passportIssue, setPassportIssue] = useState("")
  const [passportExpiry, setPassportExpiry] = useState("")
  const [dob, setDOB] = useState("")
  const [passportNumber, setPassportNumber] = useState("")
  const [editFullName, setEditFullName] = useState("")
  const [editPassportIssue, setEditPassportIssue] = useState("")
  const [editPassportExpiry, setEditPassportExpiry] = useState("")
  const [editDOB, setEditDOB] = useState("")
  const [editPassportNumber, setEditPassportNumber] = useState("")
  const [deleteMessage, setDeleteMessage] = useState(null)

  // handle dialog for adding flights
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  // handle dialog for editing flights
  const openEdit = (playerId) =>
    setFlightOpen((prev) => ({ ...prev, [playerId]: true }))
  const closeEdit = (playerId) =>
    setFlightOpen((prev) => ({ ...prev, [playerId]: false }))

  // react router dom
  const params = useParams()
  const navigate = useNavigate()

  // redux
  const dispatch = useDispatch()

  //user login details state from store.js
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin // deconstruct reducer

  //cambo details state from store.js
  const camboDetails = useSelector((state) => state.camboDetails)
  const { cambo, loading, error } = camboDetails

  //add player state from store.js
  const addPlayer = useSelector((state) => state.addPlayer)
  const {
    loading: loadingPlayer,
    error: errorPlayer,
    success: successPlayer,
  } = addPlayer

  const deleteCamboPlayer = useSelector((state) => state.deleteCamboPlayer)
  const { success: successDelete, error: deleteError } = deleteCamboPlayer

  // update player flights if required from store.js
  const updateFlights = useSelector((state) => state.updateFlights)
  const { success: successFlights, error: errorFlights } = updateFlights

  // dispatch/send emailjs emails
  useEffect(() => {
    if (successDelete) {
      dispatch({ type: CAMBO_PLAYER_DELETE_RESET })
      setDeleteMessage("Player deleted")
      setTimeout(() => {
        setDeleteMessage(null)
      }, 3000)
    }
    if (successPlayer) {
      dispatch({ type: CAMBO_ADDPLAYER_RESET })
      sendEmail()
    }
    if (successFlights) {
      dispatch({ type: CAMBO_FLIGHTS_UPDATE_RESET })
      window.location.reload()
    }
    if (userInfo) {
      dispatch(listCamboDetails(params.id))
    } else {
      navigate("/login")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    params,
    dispatch,
    navigate,
    userInfo,
    successPlayer,
    successFlights,
    successDelete,
  ])

  // add new player to event players
  const handleSubmit = (e) => {
    e.preventDefault()
    if (window.confirm("Are you sure you want to join?")) {
      dispatch(
        addNewPlayer(params.id, {
          arrFlight,
          arrDate,
          depFlight,
          depDate,
          fullName,
          passportNumber,
          passportIssue,
          passportExpiry,
          dob,
        })
      )
    }
    handleClose()
  }

  // edit flight details submit
  const handleEdit = (e) => {
    e.preventDefault()
    if (window.confirm("Are you sure you want to edit your travel info?")) {
      dispatch(
        updateCamboFlights(params.id, {
          _id,
          editArrFlight,
          editArrDate,
          editDepFlight,
          editDepDate,
          editFullName,
          editPassportNumber,
          editPassportIssue,
          editPassportExpiry,
          editDOB,
        })
      )
    }
  }

  // delete player handler
  const handleDelete = (_id) => {
    if (window.confirm("Are you sure you want to remove this player?")) {
      const id = _id.toString()
      dispatch(deletePlayer(params.id, { id }))
    }
  }

  // emailjs implementation for new players in event
  var templateParams = {
    fName: userInfo.firstName,
    lName: userInfo.lastName,
    name: cambo.name,
    date: cambo.dates,
  }
  const sendEmail = () => {
    emailjs
      .send(
        "service_i77x42u",
        "template_a9gzm98",
        templateParams,
        "QCRrRmDFlEjm5smNO"
      )
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text)
        },
        function (error) {
          console.log("FAILED...", error)
        }
      )
  }

  return (
    <>
      {/* checks if loading/error & displays outcome */}
      {loading ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress color='success' />
        </Box>
      ) : error ? (
        <Box sx={{ width: "100%" }}>
          <Alert severity='error'>
            <AlertTitle>Error</AlertTitle>
            {error}
          </Alert>
        </Box>
      ) : (
        <>
          <Box sx={{ width: "100%" }}>
            {/* go back button */}
            <Button
              size='small'
              variant='contained'
              component={RouterLink}
              to='/camboschedule'
            >
              Back
            </Button>

            <Box style={{ width: 200, marginTop: 20 }}>
              {deleteMessage && (
                <Alert severity='success'>{deleteMessage}</Alert>
              )}
              {deleteError && (
                <Alert severity='error'>{deleteError.message}</Alert>
              )}
            </Box>

            {/* header label */}
            <HeaderText cambo={cambo} />

            {/* event details accordion */}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <Typography>Costs:</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>${cambo.costs}</Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1a-content'
                id='panel1a-header'
              >
                <Typography>Accomodation:</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{cambo.hotel}</Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel2a-content'
                id='panel2a-header'
              >
                <Typography>Dates:</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{cambo.dates}</Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel3a-content'
                id='panel3a-header'
              >
                {/* attending players & flight edit functions */}
                <Typography>Players: {cambo.players.length}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {cambo.players.map((player) => (
                  <ListItem key={player._id}>
                    <Stack direction='column'>
                      <Stack direction='row'>
                        <Typography variant='body1'>
                          - {player.firstName} {player.lastName}, Handicap:{" "}
                          {player.handicap}
                          {/* logic to open flight edit dialog */}
                          {userInfo.firstName === player.firstName &&
                          userInfo.lastName === player.lastName &&
                          !cambo.batam ? (
                            <Button
                              onClick={() => {
                                openEdit(player._id)
                                setId(player._id)
                                setEditArrFlight(player.arrFlight)
                                setEditArrDate(player.arrDate)
                                setEditDepFlight(player.depFlight)
                                setEditDepDate(player.depDate)
                              }}
                            >
                              Edit Flights
                            </Button>
                          ) : null}
                          {userInfo.firstName === player.firstName &&
                          userInfo.lastName === player.lastName &&
                          cambo.batam ? (
                            // opens to edit arr/dep dates
                            <Button
                              onClick={() => {
                                openEdit(player._id)
                                setId(player._id)
                                setEditFullName(player.fullName)
                                setEditPassportNumber(player.passportNumber)
                                setEditPassportIssue(player.passportIssue)
                                setEditPassportExpiry(player.passportExpiry)
                                setEditDOB(player.dob)
                                setEditArrDate(player.arrDate)
                                setEditDepDate(player.depDate)
                                setEditArrFlight(player.arrFlight)
                                setEditDepFlight(player.depFlight)
                              }}
                            >
                              Edit Details
                            </Button>
                          ) : null}
                          {userInfo.isAdmin && player._id && (
                            <Button
                              style={{ color: "#FF0000" }}
                              size='small'
                              onClick={() => handleDelete(player._id)}
                            >
                              Remove
                            </Button>
                          )}
                        </Typography>

                        {/* edit flight dialog */}
                        {/* // *? conditionally renders based off event type */}
                        <EditTravelDialog
                          cambo={cambo}
                          openEdit={openEdit}
                          setId={setId}
                          player={player}
                          handleEdit={handleEdit}
                          _id={_id}
                          editArrFlight={editArrFlight}
                          editArrDate={editArrDate}
                          editDepFlight={editDepFlight}
                          editDepDate={editDepDate}
                          editFullName={editFullName}
                          editPassportNumber={editPassportNumber}
                          editPassportIssue={editPassportIssue}
                          editPassportExpiry={editPassportExpiry}
                          editDOB={editDOB}
                          setEditArrFlight={setEditArrFlight}
                          setEditArrDate={setEditArrDate}
                          setEditDepFlight={setEditDepFlight}
                          setEditDepDate={setEditDepDate}
                          setEditFullName={setEditFullName}
                          setEditPassportNumber={setEditPassportNumber}
                          setEditPassportExpiry={setEditPassportExpiry}
                          setEditPassportIssue={setEditPassportIssue}
                          setEditDOB={setEditDOB}
                          userInfo={userInfo}
                          flightOpen={flightOpen}
                          closeEdit={closeEdit}
                          errorFlights={errorFlights}
                        />
                      </Stack>
                    </Stack>
                  </ListItem>
                ))}
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel3a-content'
                id='panel3a-header'
              >
                <Typography>Daily Handicaps</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <DailyHandicaps cambo={cambo} />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel3a-content'
                id='panel3a-header'
              >
                <Typography>Itinerary:</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Itinerary cambo={cambo} />
              </AccordionDetails>
            </Accordion>
            {/* day 1 grouping */}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel3a-content'
                id='panel3a-header'
              >
                <Typography>Day 1 Groupings:</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ol>
                  {cambo.day1Groups?.map((groups, i) => (
                    <li key={i}>
                      <ol>
                        {groups?.map((object, j) => (
                          <li key={j}>
                            {object.firstName} {object.lastName}
                          </li>
                        ))}
                      </ol>
                    </li>
                  ))}
                </ol>
              </AccordionDetails>
            </Accordion>
            {/* day 2 grouping */}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel3a-content'
                id='panel3a-header'
              >
                <Typography>Day 2 Groupings:</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ol>
                  {cambo.day2Groups?.map((groups, i) => (
                    <li key={i}>
                      <ol>
                        {groups?.map((object, j) => (
                          <li key={j}>
                            {object.firstName} {object.lastName}
                          </li>
                        ))}
                      </ol>
                    </li>
                  ))}
                </ol>
              </AccordionDetails>
            </Accordion>
            {/* day 3 grouping */}
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel3a-content'
                id='panel3a-header'
              >
                <Typography>Day 3 Groupings:</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ol>
                  {cambo.day3Groups?.map((groups, i) => (
                    <li key={i}>
                      <ol>
                        {groups?.map((object, j) => (
                          <li key={j}>
                            {object.firstName} {object.lastName}
                          </li>
                        ))}
                      </ol>
                    </li>
                  ))}
                </ol>
              </AccordionDetails>
            </Accordion>
          </Box>

          {/* adds current user to event */}
          <Button
            size='small'
            variant='contained'
            onClick={handleClickOpen}
            style={{ margin: 10 }}
            disabled={!cambo.active || cambo.live}
          >
            Sign Up
          </Button>

          {/* if event is live, displays leaderboard */}
          {cambo.live && (
            <Button
              size='small'
              variant='contained'
              component={RouterLink}
              to={`/cambo/${cambo._id}/leaderboard`}
              style={{ margin: 10 }}
              disabled={!cambo.live}
            >
              Leaderboard
            </Button>
          )}

          {/* directs admins to player grid to export to excel */}
          {userInfo.isAdmin && (
            <Button
              size='small'
              variant='contained'
              component={RouterLink}
              to={`/cambo/${cambo._id}/playergrid`}
              style={{ margin: 10 }}
            >
              Player Info
            </Button>
          )}

          {/* //? add flights/ferries/passports and travel date entries */}
          {/* // *? renders conditionaly based on event type ##   */}
          <>
            <SignupDialog
              handleSubmit={handleSubmit}
              arrFlight={arrFlight}
              arrDate={arrDate}
              depFlight={depFlight}
              depDate={depDate}
              fullName={fullName}
              passportNumber={passportNumber}
              passportIssue={passportIssue}
              passportExpiry={passportExpiry}
              dob={dob}
              userInfo={userInfo}
              cambo={cambo}
              errorFLights={errorFlights}
              errorPlayer={errorPlayer}
              open={open}
              setFullName={setFullName}
              setArrDate={setArrDate}
              setArrFlight={setArrFlight}
              setDepDate={setDepDate}
              setDepFlight={setDepFlight}
              setPassportExpiry={setPassportExpiry}
              setPassportIssue={setPassportIssue}
              setPassportNumber={setPassportNumber}
              setDOB={setDOB}
              setOpen={setOpen}
              successPlayer={successPlayer}
              handleClickOpen={handleClickOpen}
              handleClose={handleClose}
              loadingPlayer={loadingPlayer}
            />
          </>
        </>
      )}
    </>
  )
}

export default CamboScreen
