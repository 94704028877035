// constant for user login/logout
export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST"
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS"
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL"
export const USER_LOGOUT = "USER_LOGOUT"

// constant for user signup
export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST"
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS"
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL"

// constant for user profile
export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST"
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS"
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL"
export const USER_DETAILS_RESET = "USER_DETAILS_RESET"

// constant for user profile update
export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST"
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS"
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL"
export const USER_UPDATE_RESET = "USER_UPDATE_RESET"

// constants for admin user lists (admin only)
export const USER_LIST_REQUEST = "USER_LIST_REQUEST"
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS"
export const USER_LIST_FAIL = "USER_LIST_FAIL"
export const USER_LIST_RESET = "USER_LIST_RESET"

// constants to delete a single user (admin only)
export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST"
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS"
export const USER_DELETE_FAIL = "USER_DELETE_FAIL"

// constant for user profile edit (admin only)
export const USER_EDIT_REQUEST = "USER_EDIT_REQUEST"
export const USER_EDIT_SUCCESS = "USER_EDIT_SUCCESS"
export const USER_EDIT_FAIL = "USER_EDIT_FAIL"
export const USER_EDIT_RESET = "USER_EDIT_RESET"

// constants for adding user scores
export const USER_SCORING_ADD_REQUEST = "USER_SCORING_ADD_REQUEST"
export const USER_SCORING_ADD_SUCCESS = "USER_SCORING_ADD_SUCCESS"
export const USER_SCORING_ADD_FAIL = "USER_SCORING_ADD_FAIL"
export const USER_SCORING_ADD_RESET = "USER_SCORING_ADD_RESET"

// constants to calculate use handicap
export const USER_CALHANDICAP_REQUEST = "USER_CALHANDICAP_REQUEST"
export const USER_CALHANDICAP_SUCCESS = "USER_CALHANDICAP_SUCCESS"
export const USER_CALHANDICAP_FAIL = "USER_CALHANDICAP_FAIL"
