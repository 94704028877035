import React from "react"
import {
  Button,
  FormControl,
  FormControlLabel,
  Typography,
  Grid,
  LinearProgress,
  Alert,
  Box,
  Switch,
} from "@mui/material"

const TigerEditForm = ({
  tiger,
  submitHandler,
  live,
  setLive,
  archived,
  setArchive,
  error,
  loading,
}) => {
  // format date data
  const formatDate = (date) => {
    const d = new Date(date)
    const yyyy = d.getFullYear()
    return `${yyyy}`
  }
  return (
    <Box component='form' noValidate autoComplete='on'>
      <Grid
        container
        spacing={{ xs: 3, md: 4 }}
        justifyContent='flex-start'
        alignItems='center'
        direction='column'
      >
        {/* Error & Loading  */}
        <Grid item xs={12} sm={12} md={12}>
          {error && <Alert severity='error'>{error}</Alert>}
          {loading && <LinearProgress color='success' />}
        </Grid>

        <Grid item xs={12}>
          <Typography variant='h5'>
            Race To Tiger {formatDate(tiger.year)}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          {/* set if tournament is live and start leaderboard */}
          <FormControl>
            <FormControlLabel
              control={
                <Switch
                  value={live}
                  id='live'
                  checked={live}
                  onChange={(e) => setLive(e.target.checked)}
                />
              }
              label='Live'
            />
          </FormControl>
          {/* set the tournament to be archived after completed */}
          <FormControl>
            <FormControlLabel
              control={
                <Switch
                  value={archived}
                  id='isArchived'
                  checked={archived}
                  onChange={(e) => setArchive(e.target.checked)}
                />
              }
              label='Archive'
            />
          </FormControl>
        </Grid>

        {/* submit button */}
        <Grid item xs={12} sm={12} md={12}>
          <FormControl>
            <Button type='submit' onClick={submitHandler} variant='contained'>
              Submit
            </Button>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  )
}

export default TigerEditForm
