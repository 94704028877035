import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import {
  listCamboDetails,
  updateCambo,
  sortCamboPlayers,
} from "../../actions/camboActions"
import { syncTiger } from "../../actions/tigerActions"
import { listCourses } from "../../actions/courseActions"
import FormControlLabel from "@mui/material/FormControlLabel"
import Switch from "@mui/material/Switch"
import { CAMBO_UPDATE_RESET } from "../../constants/camboConstants"
import Autocomplete from "@mui/material/Autocomplete"
import {
  OutlinedInput,
  InputLabel,
  Button,
  FormControl,
  Typography,
  Grid,
  LinearProgress,
  Alert,
  Box,
  Stack,
  TextField,
} from "@mui/material"

const EditCambo = () => {
  // react router dom v6 methods
  const navigate = useNavigate()
  const params = useParams()
  const camboId = params.id

  // redux
  const dispatch = useDispatch()

  //cambo details state from store.js
  const camboDetails = useSelector((state) => state.camboDetails)
  const { cambo } = camboDetails

  // course list  state from store
  const courseList = useSelector((state) => state.courseList)
  const { error: courseError, courses } = courseList

  // edit cambo state from store.js
  const editCambo = useSelector((state) => state.editCambo)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = editCambo

  const sortPlayers = useSelector((state) => state.sortPlayers)
  const { error: sortError, success: sortSuccess } = sortPlayers

  const tigerSync = useSelector((state) => state.tigerSync)
  const { error: syncError } = tigerSync

  // component state
  const [name, setName] = useState("")
  const [country, setCountry] = useState("")
  const [dates, setDates] = useState("")
  const [costs, setCosts] = useState(0)
  const [active, setActive] = useState(true)
  const [year, setYear] = useState("")
  const [live, setLive] = useState(false)
  const [isArchived, setArchive] = useState(false)
  const [batam, setBatam] = useState(false)
  const [hotel, setHotel] = useState("")
  const [day1Time, setDay1Time] = useState("")
  const [day2Time, setDay2Time] = useState("")
  const [day3Time, setDay3Time] = useState("")
  const [day4Time, setDay4Time] = useState("")
  const [day1Tee, setDay1Tee] = useState("")
  const [day2Tee, setDay2Tee] = useState("")
  const [day3Tee, setDay3Tee] = useState("")
  const [day4Tee, setDay4Tee] = useState("")
  const [course1, setCourse1] = useState(null)
  const [course2, setCourse2] = useState(null)
  const [course3, setCourse3] = useState(null)
  const [course4, setCourse4] = useState(null)

  // dispatch
  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: CAMBO_UPDATE_RESET })
      navigate("/camboschedule")
    } else {
      if (cambo._id !== camboId) {
        dispatch(listCamboDetails(camboId))
        dispatch(listCourses())
      } else {
        setName(cambo.name)
        setCountry(cambo.country)
        setDates(cambo.dates)
        setCosts(cambo.costs)
        setActive(cambo.active)
        setYear(cambo.year)
        setArchive(cambo.isArchived)
        setLive(cambo.live)
        setBatam(cambo.batam)
        setHotel(cambo.hotel)
        setDay1Time(cambo.day1Time)
        setDay2Time(cambo.day2Time)
        setDay3Time(cambo.day3Time)
        setDay4Time(cambo.day4Time)
        setDay1Tee(cambo.day1Tee)
        setDay2Tee(cambo.day2Tee)
        setDay3Tee(cambo.day3Tee)
        setDay4Tee(cambo.day4Tee)
        setCourse1(cambo.course1)
        setCourse2(cambo.course2)
        setCourse3(cambo.course3)
        setCourse4(cambo.course4)
      }
    }
  }, [dispatch, cambo, camboId, navigate, successUpdate])

  // submit edit handler
  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      updateCambo({
        _id: camboId,
        name,
        country,
        dates,
        costs,
        active,
        year,
        live,
        isArchived,
        batam,
        hotel,
        day1Time,
        day2Time,
        day3Time,
        day4Time,
        day1Tee,
        day2Tee,
        day3Tee,
        day4Tee,
        course1,
        course2,
        course3,
        course4,
      })
    )
    if (isArchived) {
      dispatch(syncTiger())
    }
  }

  // sort players into their daily groupings
  const sortSubmit = (cambo) => {
    // e.preventDefault()
    dispatch(
      sortCamboPlayers({
        _id: camboId,
      })
    )
  }

  return (
    <Box component='form' noValidate autoComplete='on'>
      <Grid
        container
        spacing={{ xs: 3, md: 4 }}
        justifyContent='flex-start'
        alignItems='center'
        direction='column'
      >
        {/* header label */}
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant='h2'>Edit</Typography>
        </Grid>

        {/* Error & Loading  */}
        <Grid item xs={12} sm={12} md={12}>
          {errorUpdate && <Alert severity='error'>{errorUpdate}</Alert>}
          {syncError && <Alert severity='error'>{syncError}</Alert>}
          {courseError && <Alert severity='error'>{courseError}</Alert>}
          {sortError && <Alert severity='error'>{sortError}</Alert>}
          {sortSuccess && <Alert severity='success'>{sortSuccess}</Alert>}
          {loadingUpdate && <LinearProgress color='success' />}
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          {/* event name input */}
          <Stack direction='row' spacing={3}>
            <FormControl>
              <InputLabel htmlFor='name'>Event Name</InputLabel>
              <OutlinedInput
                id='name'
                value={name}
                onChange={(e) => setName(e.target.value)}
                label='event name'
              />
            </FormControl>

            {/* event country input */}
            <FormControl>
              <InputLabel htmlFor='country'>Country</InputLabel>
              <OutlinedInput
                id='country'
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                label='country'
              />
            </FormControl>

            {/* event dates input */}
            <FormControl>
              <InputLabel htmlFor='dates'>Dates</InputLabel>
              <OutlinedInput
                id='dates'
                value={dates}
                onChange={(e) => setDates(e.target.value)}
                label='dates'
              />
            </FormControl>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          {/* event cost input */}
          <Stack direction='row' spacing={3}>
            <FormControl>
              <InputLabel htmlFor='costs'>Costs</InputLabel>
              <OutlinedInput
                id='costs'
                value={costs}
                onChange={(e) => setCosts(e.target.value)}
                label='costs'
              />
            </FormControl>

            {/* event year input */}
            <FormControl>
              <InputLabel htmlFor='year'>Year</InputLabel>
              <OutlinedInput
                id='year'
                value={year}
                onChange={(e) => setYear(e.target.value)}
                label='year'
              />
            </FormControl>

            {/* event hotel input */}
            <FormControl>
              <InputLabel htmlFor='hotel'>Hotel</InputLabel>
              <OutlinedInput
                id='hotel'
                value={hotel}
                onChange={(e) => setHotel(e.target.value)}
                label='hotel'
              />
            </FormControl>
          </Stack>
        </Grid>

        {/* itinerary label */}
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant='h6'>Itinerary</Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Stack direction='row' spacing={3}>
            {/* day 1 bus time */}
            <FormControl>
              <InputLabel htmlFor='day1time'>Day 1 Bus Time</InputLabel>
              <OutlinedInput
                id='day1time'
                value={day1Time}
                onChange={(e) => setDay1Time(e.target.value)}
                label='day1time'
              />
            </FormControl>

            {/* day 1 tee time */}
            <FormControl>
              <InputLabel htmlFor='day1tee'>Day 1 Tee Time</InputLabel>
              <OutlinedInput
                id='day1tee'
                value={day1Tee}
                onChange={(e) => setDay1Tee(e.target.value)}
                label='day1tee'
              />
            </FormControl>

            {/* day 1 course */}
            <FormControl>
              <Autocomplete
                disablePortal
                options={courses}
                getOptionLabel={(option) =>
                  option.clubName + " " + option.courseName
                }
                isOptionEqualToValue={(option, value) =>
                  option.clubName === value.clubName &&
                  option.courseName === value.courseName &&
                  option.ratingWhite === value.ratingWhite &&
                  option.slopeWhite === value.slopeWhite &&
                  option.par === value.par
                }
                id='course1'
                onChange={(event, newValue) => {
                  setCourse1(newValue)
                }}
                value={course1}
                sx={{ width: 200 }}
                renderInput={(params) => (
                  <TextField {...params} label='Course 1' />
                )}
              />
            </FormControl>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Stack direction='row' spacing={3}>
            {/* day 2 bus time */}
            <FormControl>
              <InputLabel htmlFor='day2time'>Day 2 Bus Time</InputLabel>
              <OutlinedInput
                id='day2time'
                value={day2Time}
                onChange={(e) => setDay2Time(e.target.value)}
                label='day2time'
              />
            </FormControl>

            {/* day 2 tee time */}
            <FormControl>
              <InputLabel htmlFor='day2tee'>Day 2 Tee Time</InputLabel>
              <OutlinedInput
                id='day2tee'
                value={day2Tee}
                onChange={(e) => setDay2Tee(e.target.value)}
                label='day2tee'
              />
            </FormControl>

            {/* day 2 course  */}
            <FormControl>
              <Autocomplete
                disablePortal
                options={courses}
                getOptionLabel={(option) =>
                  option.clubName + " " + option.courseName
                }
                isOptionEqualToValue={(option, value) =>
                  option.clubName === value.clubName &&
                  option.courseName === value.courseName &&
                  option.ratingWhite === value.ratingWhite &&
                  option.slopeWhite === value.slopeWhite &&
                  option.par === value.par
                }
                id='course2'
                onChange={(event, newValue) => {
                  setCourse2(newValue)
                }}
                value={course2}
                sx={{ width: 200 }}
                renderInput={(params) => (
                  <TextField {...params} label='Course 2' />
                )}
              />
            </FormControl>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Stack direction='row' spacing={3}>
            {/* day 3 bus time  */}
            <FormControl>
              <InputLabel htmlFor='day3time'>Day 3 Bus Time</InputLabel>
              <OutlinedInput
                id='day3time'
                value={day3Time}
                onChange={(e) => setDay3Time(e.target.value)}
                label='day3time'
              />
            </FormControl>

            {/* day 3 tee time */}
            <FormControl>
              <InputLabel htmlFor='day3tee'>Day 3 Tee Time</InputLabel>
              <OutlinedInput
                id='day3tee'
                value={day3Tee}
                onChange={(e) => setDay3Tee(e.target.value)}
                label='day3tee'
              />
            </FormControl>

            {/* day 3 course */}
            <FormControl>
              <Autocomplete
                disablePortal
                options={courses}
                getOptionLabel={(option) =>
                  option.clubName + " " + option.courseName
                }
                isOptionEqualToValue={(option, value) =>
                  option.clubName === value.clubName &&
                  option.courseName === value.courseName &&
                  option.ratingWhite === value.ratingWhite &&
                  option.slopeWhite === value.slopeWhite &&
                  option.par === value.par
                }
                id='course3'
                onChange={(event, newValue) => {
                  setCourse3(newValue)
                }}
                value={course3}
                sx={{ width: 200 }}
                renderInput={(params) => (
                  <TextField {...params} label='Course 3' />
                )}
              />
            </FormControl>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Stack direction='row' spacing={3}>
            {/* day 4 bus time  */}
            <FormControl>
              <InputLabel htmlFor='day4time'>Day 4 Bus Time</InputLabel>
              <OutlinedInput
                id='day4time'
                value={day4Time}
                onChange={(e) => setDay4Time(e.target.value)}
                label='day4time'
              />
            </FormControl>

            {/* day 4 tee time */}
            <FormControl>
              <InputLabel htmlFor='day4tee'>Day 4 Tee Time</InputLabel>
              <OutlinedInput
                id='day4tee'
                value={day4Tee}
                onChange={(e) => setDay4Tee(e.target.value)}
                label='day4tee'
              />
            </FormControl>

            {/* day 4 course */}
            <FormControl>
              <Autocomplete
                disablePortal
                options={courses}
                getOptionLabel={(option) =>
                  option.clubName + " " + option.courseName
                }
                isOptionEqualToValue={(option, value) =>
                  option.clubName === value.clubName &&
                  option.courseName === value.courseName &&
                  option.ratingWhite === value.ratingWhite &&
                  option.slopeWhite === value.slopeWhite &&
                  option.par === value.par
                }
                id='course4'
                onChange={(event, newValue) => {
                  setCourse4(newValue)
                }}
                value={course4}
                sx={{ width: 200 }}
                renderInput={(params) => (
                  <TextField {...params} label='Course 4' />
                )}
              />
            </FormControl>
          </Stack>
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Stack direction='column' spacing={1}>
            {/* set if tounament is active for entry/register */}
            <FormControl>
              <FormControlLabel
                control={
                  <Switch
                    value={active}
                    id='active'
                    checked={active}
                    onChange={(e) => setActive(e.target.checked)}
                  />
                }
                label='Active'
              />
              {/* set if tournament is live and start leaderboard */}
              <FormControlLabel
                control={
                  <Switch
                    value={live}
                    id='live'
                    checked={live}
                    onChange={(e) => setLive(e.target.checked)}
                  />
                }
                label='Live'
              />
            </FormControl>
            {/* set the tournament to be archived after completed */}
            <FormControl>
              <FormControlLabel
                control={
                  <Switch
                    value={isArchived}
                    id='isArchived'
                    checked={isArchived}
                    onChange={(e) => setArchive(e.target.checked)}
                  />
                }
                label='Archive'
              />
            </FormControl>
            {/* sets event to batam based event */}
            <FormControl>
              <FormControlLabel
                control={
                  <Switch
                    value={batam}
                    id='batam'
                    checked={batam}
                    onChange={(e) => setBatam(e.target.checked)}
                  />
                }
                label='Batam Event'
              />
            </FormControl>
          </Stack>
        </Grid>

        {/* submit button */}
        <Grid item xs={12} sm={12} md={12}>
          <FormControl>
            <Button type='submit' onClick={submitHandler} variant='contained'>
              Submit
            </Button>
          </FormControl>
        </Grid>

        {/*sort submit button */}
        <Grid item xs={12} sm={12} md={12}>
          <FormControl>
            <Button type='submit' onClick={sortSubmit} variant='contained'>
              Sort Players
            </Button>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  )
}

export default EditCambo
