import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { register } from "../../actions/userActions"
import emailjs from "@emailjs/browser"
import countries from "../../utils/countries"
import {
  OutlinedInput,
  InputLabel,
  Button,
  FormControl,
  Typography,
  Grid,
  LinearProgress,
  Alert,
  Box,
  Stack,
  Select,
  MenuItem,
} from "@mui/material"

const Register = () => {
  // react router dom v6 methods
  const location = useLocation()
  const navigate = useNavigate()

  // redux
  const dispatch = useDispatch()

  // user register state from store.js
  const userRegister = useSelector((state) => state.userRegister)
  const { loading, userInfo, error } = userRegister

  // component state
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [password1, setPassword1] = useState("")
  const [country, setCountry] = useState("")
  const [message, setMessage] = useState(null)

  // redirect from url
  const redirect = location.search ? location.search.split("=")[1] : "/"

  // redirects if already logged in
  useEffect(() => {
    if (userInfo) {
      navigate(redirect)

      // implementation of emailjs for new user registration
    var templateParams = {
      fName: firstName,
      lName: lastName,
    }
    const sendEmail = () => {
      emailjs
        .send(
          "service_i77x42u",
          "template_fi6s7nf",
          templateParams,
          "QCRrRmDFlEjm5smNO"
        )
        .then(
          function (response) {
            console.log("SUCCESS!", response.status, response.text)
          },
          function (error) {
            console.log("FAILED...", error)
          }
        )
    }
      sendEmail()
    }

  }, [userInfo, navigate, redirect, firstName, lastName])

  // submit register handler
  const submitHandler = (e) => {
    e.preventDefault()
    if (password !== password1) {
      setMessage("Passwords do not match")
    } else {
      dispatch(register(firstName, lastName, email, password, country))
    }
  }

  return (
    <Box component='form' noValidate autoComplete='off'>
      <Grid
        container
        spacing={{ xs: 3, md: 4 }}
        justifyContent='flex-start'
        alignItems='center'
        direction='column'
      >
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant='h2'>Sign Up</Typography>
        </Grid>

        {/* Error & Loading  */}
        <Grid item xs={12} sm={12} md={12}>
          {message && <Alert severity='error'>{message}</Alert>}
          {error && <Alert severity='error'>{error}</Alert>}
          {loading && <LinearProgress color='success' />}
        </Grid>

        {/*first  name input */}
        <Grid item xs={12} sm={12} md={12}>
          <Stack direction='row' spacing={3}>
            <FormControl>
              <InputLabel htmlFor='firstName'>First Name</InputLabel>
              <OutlinedInput
                id='lastName'
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                label='First Name'
              />
            </FormControl>

            {/* last name input */}
            <FormControl>
              <InputLabel htmlFor='lastName'>Last Name</InputLabel>
              <OutlinedInput
                id='firstName'
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                label='Last Name'
              />
            </FormControl>
          </Stack>
        </Grid>

        {/* email address input */}
        <Grid item xs={12} sm={12} md={12}>
          <FormControl>
            <InputLabel htmlFor='email'>Email</InputLabel>
            <OutlinedInput
              type='email'
              id='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              label='Email'
            />
          </FormControl>
        </Grid>

        {/* country input */}
        <Grid item xs={12} sm={12} md={12}>
          <FormControl>
            <InputLabel htmlFor='country'>Nationality</InputLabel>
            <Select
              style={{ width: 130, height: 40 }}
              labelId='country'
              id='country'
              value={country}
              label='country'
              onChange={(e) => setCountry(e.target.value)}
            >
              {countries?.map((country) => {
                return (
                  <MenuItem key={country.value} value={country.value}>
                    {country.label ?? country.value}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>

        {/* password input */}
        <Grid item xs={12} sm={12} md={12}>
          <FormControl>
            <InputLabel htmlFor='password'>Password</InputLabel>
            <OutlinedInput
              id='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              label='Password'
              type='password'
            />
          </FormControl>
        </Grid>

        {/* confirm password input */}
        <Grid item xs={12} sm={12} md={12}>
          <FormControl>
            <InputLabel htmlFor='password'>Confirm Password</InputLabel>
            <OutlinedInput
              id='password1'
              value={password1}
              onChange={(e) => setPassword1(e.target.value)}
              label='Confirm Password'
              type='password'
            />
          </FormControl>
        </Grid>

        {/* submit button */}
        <Grid item xs={12} sm={12} md={12}>
          <FormControl>
            <Button type='submit' onClick={submitHandler} variant='contained'>
              Sign Up
            </Button>
          </FormControl>
        </Grid>

        {/* link to login page */}
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant='h6'>
            Existing Golfer?{" "}
            <Link to={redirect ? `/?redirect=${redirect}` : "/"}>Login</Link>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Register
