import React, { useEffect } from "react"
import { Link as RouterLink } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { listCourses, deleteCourse } from "../../actions/courseActions"
import {
  Button,
  Typography,
  Grid,
  LinearProgress,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material"

const CourseAdmin = () => {
  // redux method
  const dispatch = useDispatch()

  // reacter router v6 methods
  const navigate = useNavigate()

  // course list  state from store
  const courseList = useSelector((state) => state.courseList)
  const { loading, error, courses } = courseList // destructuring

  // user state from store
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin // destructuring

  // course delete state from store
  const courseDelete = useSelector((state) => state.courseDelete)
  const { success: successDelete } = courseDelete // destructuring

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listCourses())
    } else {
      navigate("/login")
    }
  }, [dispatch, navigate, userInfo, successDelete])

  // delete course
  const deleteHandler = (id) => {
    if (window.confirm("Are you sure you want to delete this course?")) {
      dispatch(deleteCourse(id))
    }
  }
  return (
    <Grid
      container
      spacing={{ xs: 3, md: 4 }}
      justifyContent='flex-start'
      alignItems='center'
      direction='column'
    >
      {/* loading and error  */}
      <Typography variant='h3'>Courses</Typography>
      {loading ? (
        <LinearProgress severity='success' />
      ) : error ? (
        <Alert severity='error'>{error}</Alert>
      ) : (
        // course table
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell>Club Name</TableCell>
                <TableCell>Course Name</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {courses
                .sort((a, b) => a.clubName.localeCompare(b.clubName))
                .map((course) => (
                  <TableRow key={course._id}>
                    <TableCell>{course.clubName}</TableCell>
                    <TableCell>{course.courseName}</TableCell>
                    <TableCell>
                      {/* edit & delete course buttons */}
                      <Button
                        size='small'
                        component={RouterLink}
                        to={`/admin/course/${course._id}/edit`}
                      >
                        Edit
                      </Button>

                      <Button
                        style={{ color: "#FF0000" }}
                        size='small'
                        onClick={() => deleteHandler(course._id)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Grid>
  )
}

export default CourseAdmin
