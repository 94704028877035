import React from "react"
import CamboLogo from "../../../images/camboLogo.png"
import Card from "@mui/material/Card"
import CardActions from "@mui/material/CardActions"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import { Button, Typography } from "@mui/material"
import { Link as RouterLink } from "react-router-dom"

const UpcomingEvents = () => {
  return (
    <Card sx={{ width: 345 }}>
      <CardMedia component='img' height='250' src={CamboLogo} alt='Cambo Cup' />
      <CardContent>
        <Typography gutterBottom variant='h5' component='div'>
          Upcoming Cambo Events
        </Typography>
      </CardContent>
      <CardActions>
        <Button component={RouterLink} to='/camboschedule' size='small'>
          Browse Schedule
        </Button>
      </CardActions>
    </Card>
  )
}

export default UpcomingEvents
