import axios from "axios"

// import cambo constants
import {
  CAMBO_LIST_REQUEST,
  CAMBO_LIST_SUCCESS,
  CAMBO_LIST_FAIL,
  CAMBO_DETAILS_REQUEST,
  CAMBO_DETAILS_SUCCESS,
  CAMBO_DETAILS_FAIL,
  CAMBO_ADDPLAYER_REQUEST,
  CAMBO_ADDPLAYER_SUCCESS,
  CAMBO_ADDPLAYER_FAIL,
  CAMBO_CREATE_REQUEST,
  CAMBO_CREATE_SUCCESS,
  CAMBO_CREATE_FAIL,
  CAMBO_UPDATE_REQUEST,
  CAMBO_UPDATE_SUCCESS,
  CAMBO_UPDATE_FAIL,
  CAMBO_DELETE_REQUEST,
  CAMBO_DELETE_SUCCESS,
  CAMBO_DELETE_FAIL,
  CAMBO_SCORING_UPDATE_REQUEST,
  CAMBO_SCORING_UPDATE_SUCCESS,
  CAMBO_SCORING_UPDATE_FAIL,
  CAMBO_FLIGHTS_UPDATE_REQUEST,
  CAMBO_FLIGHTS_UPDATE_SUCCESS,
  CAMBO_FLIGHTS_UPDATE_FAIL,
  CAMBO_SORTPLAYER_REQUEST,
  CAMBO_SORTPLAYER_SUCCESS,
  CAMBO_SORTPLAYER_FAIL,
  CAMBO_PLAYER_DELETE_REQUEST,
  CAMBO_PLAYER_DELETE_SUCCESS,
  CAMBO_PLAYER_DELETE_FAIL,
} from "../constants/camboConstants"

// list cambo action
export const listCambos = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CAMBO_LIST_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/cambocups`, config)
    dispatch({ type: CAMBO_LIST_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: CAMBO_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// list cambo details action creator for cambo screen
export const listCamboDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: CAMBO_DETAILS_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(`/api/cambocups/${id}`, config)
    dispatch({ type: CAMBO_DETAILS_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: CAMBO_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// add player to cambo action
export const addNewPlayer = (camboId, player) => async (dispatch, getState) => {
  try {
    dispatch({ type: CAMBO_ADDPLAYER_REQUEST }) // add player request

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.post(`/api/cambocups/${camboId}/players`, player, config)

    dispatch({ type: CAMBO_ADDPLAYER_SUCCESS }) //add player success
  } catch (error) {
    dispatch({
      type: CAMBO_ADDPLAYER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// delete a player from cambo action
export const deletePlayer = (camboId, player) => async (dispatch, getState) => {
  try {
    dispatch({ type: CAMBO_PLAYER_DELETE_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const { data } = await axios.delete(
      `/api/cambocups/${camboId}/deleteplayer`,
      { data: player },
      config
    )
    console.log(player)

    dispatch({ type: CAMBO_PLAYER_DELETE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: CAMBO_PLAYER_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// create cambo (admin only)
export const createCambo = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: CAMBO_CREATE_REQUEST }) //CREATE request

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`/api/cambocups`, {}, config)

    dispatch({ type: CAMBO_CREATE_SUCCESS, payload: data }) //CREATE success
  } catch (error) {
    dispatch({
      type: CAMBO_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// update cambo (admin only)
export const updateCambo = (cambo) => async (dispatch, getState) => {
  try {
    dispatch({ type: CAMBO_UPDATE_REQUEST }) // product UPDATE request

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(
      `/api/cambocups/${cambo._id}`,
      cambo,
      config
    )

    dispatch({ type: CAMBO_UPDATE_SUCCESS, payload: data }) //UPDATE success
  } catch (error) {
    dispatch({
      type: CAMBO_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// delete a cambo (admin only)
export const deleteCambo = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: CAMBO_DELETE_REQUEST }) // DELETE request

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`/api/cambocups/${id}`, config)

    dispatch({ type: CAMBO_DELETE_SUCCESS }) //DELETE success
  } catch (error) {
    dispatch({
      type: CAMBO_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// update cambo scoring (admin only)
export const updateCamboScoring =
  (camboId, scoring) => async (dispatch, getState) => {
    try {
      dispatch({ type: CAMBO_SCORING_UPDATE_REQUEST })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.put(
        `/api/cambocups/${camboId}/scoring`,
        scoring,
        config
      )
      dispatch({ type: CAMBO_SCORING_UPDATE_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: CAMBO_SCORING_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

// update cambo player flights
export const updateCamboFlights =
  (camboId, flights) => async (dispatch, getState) => {
    try {
      dispatch({ type: CAMBO_FLIGHTS_UPDATE_REQUEST })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
      const { data } = await axios.put(
        `/api/cambocups/${camboId}/flights`,
        flights,
        config
      )
      dispatch({ type: CAMBO_FLIGHTS_UPDATE_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: CAMBO_FLIGHTS_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

// sort players into groups (admin only)
export const sortCamboPlayers = (cambo) => async (dispatch, getState) => {
  try {
    dispatch({ type: CAMBO_SORTPLAYER_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(
      `/api/cambocups/${cambo._id}/sort`,
      cambo,
      config
    )

    dispatch({ type: CAMBO_SORTPLAYER_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: CAMBO_SORTPLAYER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
