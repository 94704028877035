import React from "react"
import { TableCell } from "@mui/material"

export const Winner = ({ cambo }) => {
  const maxScore = Math.max(...cambo.players.map((player) => player.total))
  const maxScoringPlayer = cambo.players.find(
    (player) => player.total === maxScore
  )
  return (
    <TableCell>
      {maxScoringPlayer.firstName} {maxScoringPlayer.lastName}
    </TableCell>
  )
}
