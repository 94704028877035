import React, { useEffect } from "react"
import { Link as RouterLink } from "react-router-dom"
import { useParams, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { listCamboDetails } from "../../../actions/camboActions"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import ReactCountryFlag from "react-country-flag"
import {
  Box,
  Typography,
  Button,
  LinearProgress,
  Alert,
  AlertTitle,
} from "@mui/material"
import MobileBasicLeaberboard from "./MobileBasicLeaberboard"

const BasicLeaderboard = () => {
  // react router dom
  const params = useParams()
  const navigate = useNavigate()

  // redux
  const dispatch = useDispatch()

  //user details state from store.js
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  //cambo details state from store.js
  const camboDetails = useSelector((state) => state.camboDetails)
  const { cambo, loading, error } = camboDetails

  // dispatch
  useEffect(() => {
    if (userInfo) {
      dispatch(listCamboDetails(params.id))
    } else {
      navigate("/login")
    }
  }, [params, dispatch, navigate, userInfo])

  return (
    <>
      {/* checks if loading/error & displays outcome */}
      {loading ? (
        <Box sx={{ width: "100%" }}>
          <LinearProgress color='success' />
        </Box>
      ) : error ? (
        <Box sx={{ width: "100%" }}>
          <Alert severity='error'>
            <AlertTitle>Error</AlertTitle>
            {error}
          </Alert>
        </Box>
      ) : (
        <>
          {/* go back button */}
          <Button
            size='small'
            variant='contained'
            component={RouterLink}
            to={`/archivedevents`}
          >
            Back
          </Button>

          {/* header label */}
          <Typography variant='h4'>{cambo.country} Leaderboard</Typography>

          {/* leaderboard table */}
          <TableContainer
            component={Paper}
            sx={{ display: { xs: "none", md: "block" } }}
          >
            <Table sx={{ minWidth: 650 }} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell>
                    <Typography variant='h6'>Total</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant='h6'>Rd 1</Typography>
                  </TableCell>
                  <TableCell>HDC</TableCell>
                  <TableCell>
                    <Typography variant='h6'>Rd 2</Typography>
                  </TableCell>
                  <TableCell>HDC</TableCell>
                  <TableCell>
                    <Typography variant='h6'>Rd 3</Typography>
                  </TableCell>
                  <TableCell>HDC</TableCell>
                  <TableCell>
                    <Typography variant='h6'>Rd 4</Typography>
                  </TableCell>
                  <TableCell>HDC</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* sorts player leader from highest total */}
                {/* chc stands for course handicap - different each course */}
                {cambo.players
                  .sort((a, b) => b.total - a.total)
                  .map((player, i) => (
                    <React.Fragment key={player._id}>
                      <TableRow>
                        <TableCell>
                          <ReactCountryFlag
                            countryCode={player.country}
                            svg
                            style={{
                              fontSize: "2em",
                              lineHeight: "2em",
                            }}
                          />
                        </TableCell>
                        <TableCell>{player.firstName} </TableCell>
                        <TableCell>{player.lastName}</TableCell>
                        <TableCell
                          align='center'
                          sx={{
                            padding: "0px 0px",
                            backgroundColor: "lightgray",
                            color: "red",
                          }}
                        >
                          {player.total}
                        </TableCell>
                        <TableCell align='center'>{player.dayOne}</TableCell>
                        <TableCell
                          align='center'
                          sx={{
                            padding: "0px 0px",
                            color: "blue",
                          }}
                        >
                          {player.chc1}
                        </TableCell>
                        <TableCell
                          align='center'
                          sx={{
                            padding: "0px 0px",
                            backgroundColor: "lightgray",
                          }}
                        >
                          {player.dayTwo}
                        </TableCell>
                        <TableCell
                          align='center'
                          sx={{
                            padding: "0px 0px",
                            color: "blue",
                            backgroundColor: "lightgray",
                          }}
                        >
                          {player.chc2}
                        </TableCell>
                        <TableCell align='center'>{player.dayThree}</TableCell>
                        <TableCell
                          align='center'
                          sx={{
                            padding: "0px 0px",
                            color: "blue",
                          }}
                        >
                          {player.chc3}
                        </TableCell>
                        <TableCell
                          align='center'
                          sx={{
                            padding: "0px 0px",
                            backgroundColor: "lightgray",
                          }}
                        >
                          {player.dayFour}
                        </TableCell>
                        <TableCell
                          align='center'
                          sx={{
                            padding: "0px 0px",
                            color: "blue",
                            backgroundColor: "lightgray",
                          }}
                        >
                          {player.chc4}
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* displays only on mobile devices */}
          <MobileBasicLeaberboard cambo={cambo} />
        </>
      )}
    </>
  )
}

export default BasicLeaderboard
