import React from "react"
import SportsGolfIcon from "@mui/icons-material/SportsGolf"

const TeeIcon = ({ score }) => {
  return (
    <>
      {score.tee === "black" && <SportsGolfIcon style={{ color: "#000000" }} />}
      {score.tee === "blue" && <SportsGolfIcon style={{ color: "#0017FF" }} />}
      {score.tee === "white" && <SportsGolfIcon style={{ color: "#DFDFDF" }} />}
      {score.tee === "red" && <SportsGolfIcon style={{ color: "#FF0000" }} />}
    </>
  )
}

export default TeeIcon
