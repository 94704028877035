// import course constants
import {
  CAMBO_LIST_REQUEST,
  CAMBO_LIST_SUCCESS,
  CAMBO_LIST_FAIL,
  CAMBO_DETAILS_REQUEST,
  CAMBO_DETAILS_SUCCESS,
  CAMBO_DETAILS_FAIL,
  CAMBO_ADDPLAYER_REQUEST,
  CAMBO_ADDPLAYER_SUCCESS,
  CAMBO_ADDPLAYER_FAIL,
  CAMBO_ADDPLAYER_RESET,
  CAMBO_CREATE_REQUEST,
  CAMBO_CREATE_SUCCESS,
  CAMBO_CREATE_FAIL,
  CAMBO_CREATE_RESET,
  CAMBO_UPDATE_REQUEST,
  CAMBO_UPDATE_SUCCESS,
  CAMBO_UPDATE_FAIL,
  CAMBO_UPDATE_RESET,
  CAMBO_DELETE_REQUEST,
  CAMBO_DELETE_SUCCESS,
  CAMBO_DELETE_FAIL,
  CAMBO_SCORING_UPDATE_REQUEST,
  CAMBO_SCORING_UPDATE_SUCCESS,
  CAMBO_SCORING_UPDATE_FAIL,
  CAMBO_SCORING_UPDATE_RESET,
  CAMBO_FLIGHTS_UPDATE_REQUEST,
  CAMBO_FLIGHTS_UPDATE_SUCCESS,
  CAMBO_FLIGHTS_UPDATE_FAIL,
  CAMBO_FLIGHTS_UPDATE_RESET,
  CAMBO_SORTPLAYER_REQUEST,
  CAMBO_SORTPLAYER_SUCCESS,
  CAMBO_SORTPLAYER_FAIL,
  CAMBO_PLAYER_DELETE_REQUEST,
  CAMBO_PLAYER_DELETE_SUCCESS,
  CAMBO_PLAYER_DELETE_FAIL,
  CAMBO_PLAYER_DELETE_RESET,
} from "../constants/camboConstants"

// cambo list reducer
export const camboListReducer = (state = { cambos: [] }, action) => {
  switch (action.type) {
    case CAMBO_LIST_REQUEST:
      return { loading: true, cambos: [] }
    case CAMBO_LIST_SUCCESS:
      return {
        loading: false,
        cambos: action.payload,
      }
    case CAMBO_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

// cambo details reducer
export const camboDetailsReducer = (
  state = { cambo: { players: [], golfCourses: [] } },
  action
) => {
  switch (action.type) {
    case CAMBO_DETAILS_REQUEST:
      return { loading: true, ...state }
    case CAMBO_DETAILS_SUCCESS:
      return { loading: false, cambo: action.payload }
    case CAMBO_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

// add player reducer
export const addPlayerReducer = (state = {}, action) => {
  switch (action.type) {
    case CAMBO_ADDPLAYER_REQUEST:
      return { loading: true }
    case CAMBO_ADDPLAYER_SUCCESS:
      return { loading: false, success: true }
    case CAMBO_ADDPLAYER_FAIL:
      return { loading: false, error: action.payload }
    case CAMBO_ADDPLAYER_RESET:
      return {}
    default:
      return state
  }
}

// delete player reducer
export const deletePlayerReducer = (state = {}, action) => {
  switch (action.type) {
    case CAMBO_PLAYER_DELETE_REQUEST:
      return { loading: true }
    case CAMBO_PLAYER_DELETE_SUCCESS:
      return { loading: false, success: true }
    case CAMBO_PLAYER_DELETE_FAIL:
      return { loading: false, error: action.payload }
    case CAMBO_PLAYER_DELETE_RESET:
      return {}
    default:
      return state
  }
}

// cambo create reducer (admin only)
export const camboCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CAMBO_CREATE_REQUEST:
      return { loading: true }
    case CAMBO_CREATE_SUCCESS:
      return { loading: false, success: true, cambo: action.payload }
    case CAMBO_CREATE_FAIL:
      return { loading: false, error: action.payload }
    case CAMBO_CREATE_RESET:
      return {}
    default:
      return state
  }
}

// cambo update scoring reducer (admin only)
export const camboUpdateScoringReducer = (state = { cambo: {} }, action) => {
  switch (action.type) {
    case CAMBO_SCORING_UPDATE_REQUEST:
      return { loading: true }
    case CAMBO_SCORING_UPDATE_SUCCESS:
      return { loading: false, success: true, cambo: action.payload }
    case CAMBO_SCORING_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case CAMBO_SCORING_UPDATE_RESET:
      return { cambo: {} }
    default:
      return state
  }
}

// cambo update scoring reducer (admin only)
export const camboUpdateFlightReducer = (state = { cambo: {} }, action) => {
  switch (action.type) {
    case CAMBO_FLIGHTS_UPDATE_REQUEST:
      return { loading: true }
    case CAMBO_FLIGHTS_UPDATE_SUCCESS:
      return { loading: false, success: true, cambo: action.payload }
    case CAMBO_FLIGHTS_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case CAMBO_FLIGHTS_UPDATE_RESET:
      return { cambo: {} }
    default:
      return state
  }
}

// cambo update reducer (admin only)
export const camboUpdateReducer = (state = { cambo: {} }, action) => {
  switch (action.type) {
    case CAMBO_UPDATE_REQUEST:
      return { loading: true }
    case CAMBO_UPDATE_SUCCESS:
      return { loading: false, success: true, cambo: action.payload }
    case CAMBO_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case CAMBO_UPDATE_RESET:
      return { cambo: {} }
    default:
      return state
  }
}

// cambo delete reducer (admin only)
export const camboDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CAMBO_DELETE_REQUEST:
      return { loading: true }
    case CAMBO_DELETE_SUCCESS:
      return { loading: false, success: true }
    case CAMBO_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

// cambo update reducer (admin only)
export const camboSortPlayerReducer = (state = { cambo: {} }, action) => {
  switch (action.type) {
    case CAMBO_SORTPLAYER_REQUEST:
      return { loading: true }
    case CAMBO_SORTPLAYER_SUCCESS:
      return { loading: false, success: true, cambo: action.payload }
    case CAMBO_SORTPLAYER_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
