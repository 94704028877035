import React, { useState, useEffect } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { login } from "../../actions/userActions"
import {
  OutlinedInput,
  InputLabel,
  Button,
  Typography,
  Grid,
  LinearProgress,
  Alert,
  Box,
  FormControl,
} from "@mui/material"

const Landing = () => {
  // react router dom v6 methods
  const location = useLocation()
  const navigate = useNavigate()

  // redux
  const dispatch = useDispatch()

  // login state from store.js
  const userLogin = useSelector((state) => state.userLogin)
  const { loading, userInfo, error } = userLogin

  // component state
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  // redirect from url
  const redirect = location.search
    ? location.search.split("=")[1]
    : "/dashboard"

  // redirects if already logged in
  useEffect(() => {
    if (userInfo) {
      navigate(redirect)
    }
  }, [userInfo, navigate, redirect])

  // submit login handler
  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(login(email, password))
  }
  return (
    <>
      <Box component='form' noValidate autoComplete='off'>
        <Grid
          container
          spacing={{ xs: 3, md: 4 }}
          justifyContent='flex-start'
          alignItems='center'
          direction='column'
        >
          {/* header label */}
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant='h2'>Sign In</Typography>
          </Grid>

          {/* Error & Loading  */}
          <Grid item xs={12} sm={12} md={12}>
            {error && <Alert severity='error'>{error}</Alert>}
            {loading && <LinearProgress color='success' />}
          </Grid>

          {/* email address input */}
          <Grid item xs={12} sm={12} md={12}>
            <FormControl>
              <InputLabel htmlFor='email'>Email</InputLabel>
              <OutlinedInput
                type='email'
                id='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                label='Email'
              />
            </FormControl>
          </Grid>

          {/* password input */}
          <Grid item xs={12} sm={12} md={12}>
            <FormControl>
              <InputLabel htmlFor='password'>Password</InputLabel>
              <OutlinedInput
                id='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                label='Password'
                type='password'
              />
            </FormControl>
          </Grid>

          {/* submit button */}
          <Grid item xs={12} sm={12} md={12}>
            <FormControl>
              <Button
                type='submit'
                style={{ width: "100%" }}
                onClick={submitHandler}
                variant='contained'
              >
                Sign In
              </Button>
            </FormControl>
          </Grid>

          {/* link to register page */}
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant='h6'>
              New Golfer?{" "}
              <Link
                to={redirect ? `/register?redirect=${redirect}` : "/register"}
              >
                Register
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default Landing
