import React from "react"
import { TableCell } from "@mui/material"
import ReactCountryFlag from "react-country-flag"

const WinnerFlag = ({ cambo }) => {
  const maxScore = Math.max(...cambo.players.map((player) => player.total))
  const maxScoringPlayer = cambo.players.find(
    (player) => player.total === maxScore
  )

  return (
    <TableCell>
      <ReactCountryFlag
        countryCode={maxScoringPlayer.country}
        svg
        style={{
          fontSize: "2em",
          lineHeight: "2em",
        }}
      />
    </TableCell>
  )
}

export default WinnerFlag
